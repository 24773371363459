/**
 * We roll our own auto-logout instead of fully relying on Azure AD B2C's built-in
 * session management. This is because Azure does not provide an easy way to
 * determine when the session is about expire to/has expired. Instead:
 * - When the app is open, we rely on this AutoLogout component
 * - When the app is closed, we rely on the session management provided by Azure AD B2C
 */

import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import LogoutWarningModal from './LogoutWarningModal';

const AUTO_LOGOUT_TIME = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
const ALERT_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
// const AUTO_LOGOUT_TIME = 30 * 1000; // 30 sec for testing
// const ALERT_TIME = 5 * 1000; // 5 seconds for testing

const EVENTS = [
  'load', // fires when the page has fully loaded
  'click', // user presses and releases a mouse button
  'scroll', // user scrolls
  'keypress', // any key presses, indicating that the user is typing or interacting with text inputs.
  'touchstart', // user touches the screen
  'touchmove', // user moves their finger on the screen
  'mousemove', // user moves the mouse
  'mousedown', // user presses down a mouse button
  'wheel' // user scrolls with a mouse wheel
];

const AutoLogout = () => {
  const { instance } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const saveLastActivityTimestamp = () => {
    localStorage.setItem('lastActivityTimestamp', Date.now().toString());
  };

  const logout = () => {
    instance.logoutRedirect();
  };

  const isTimestampExpired = (timestamp: string) => {
    const isExpired = Date.now() - parseInt(timestamp) > AUTO_LOGOUT_TIME;
    return isExpired;
  };

  // Check if we are in the alert time window. If so, show the modal
  const handleAlertWindow = (lastActivityTimestamp: string) => {
    const timeRemaining = AUTO_LOGOUT_TIME - (Date.now() - parseInt(lastActivityTimestamp));
    if (timeRemaining <= ALERT_TIME) {
      setIsModalOpen(true);
    }
  };

  // Start a timer that goes off every 5 minutes
  useEffect(() => {
    EVENTS.forEach((event) => window.addEventListener(event, saveLastActivityTimestamp));
    // Set an interval that triggers every 5 minutes
    const intervalId = setInterval(() => {
      // console.log(`${ALERT_TIME / (1000 * 60)} minutes have passed!`);
      const lastActivityTimestamp = localStorage.getItem('lastActivityTimestamp');
      if (!lastActivityTimestamp || isTimestampExpired(lastActivityTimestamp)) {
        logout();
      } else {
        handleAlertWindow(lastActivityTimestamp);
      }
    }, ALERT_TIME);

    // Clean up the interval when the component is unmounted
    return () => {
      EVENTS.forEach((event) => window.removeEventListener(event, saveLastActivityTimestamp));
      clearInterval(intervalId);
    };
  }, []);

  return <LogoutWarningModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />;
};

export default AutoLogout;
