import { XMarkIcon } from '@heroicons/react/24/outline';
import { TooltipRenderProps } from 'react-joyride';
import { steps } from '../data/productTourSteps';
import ProgressBar from './ProgressBar';

// Custom Tooltip Component with Dots
function CustomTooltip(props: TooltipRenderProps) {
  const { continuous, index, step, backProps, primaryProps, skipProps, tooltipProps } = props;

  return (
    <div {...tooltipProps} className="py-6 px-8 bg-white rounded-lg min-w-[500px] max-w-2xl">
      <div className="flex justify-end">
        <div {...skipProps}>
          <XMarkIcon className="top-0 h-6 w-6 text-gray-400 hover:text-indigo-600 transition-color duration-300" />
        </div>
      </div>
      {step.title && <h4 className="tooltip__title text-xl font-bold mb-2">{step.title}</h4>}
      <div className="tooltip__content">{step.content}</div>
      <div
        className="flex justify-between mt-6"
        // style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}
      >
        <div className="flex items-center">
          {index > 0 && (
            <button {...backProps} className="text-gray-500">
              Back
            </button>
          )}
          {/* <button {...skipProps} className="text-gray-400">
            Skip
          </button> */}
        </div>
        <ProgressBar steps={steps.length} activeStep={index} />
        {continuous && (
          <button
            {...primaryProps}
            className="tooltip__button tooltip__button--primary px-2.5 py-1 text-md font-semibold rounded-lg bg-indigo-600 hover:bg-indigo-400 transition-color duration-300 text-white"
          >
            {primaryProps.title}
          </button>
        )}
      </div>
    </div>
  );
}

export default CustomTooltip;
