import { AccountInfo } from '@azure/msal-browser';
import {
  AnswerValue,
  ApiError,
  CreateMeetingRequest,
  Meeting,
  MeetingServer,
  ProcessingStatus,
  TemplateBlock,
  TemplateBlockAndAnswer,
  TemplateBlockAnswer,
  TemplateBlockAnswerServer,
  TemplateBlockCreate,
  TemplateElements,
  TranscriptChunk
} from '../common/types';
import { getAccessToken } from '../common/utils/utils';
import { apiBaseUrl, msalInstance } from '../index';

const _getActiveUserName = () => {
  const activeAccount: AccountInfo | null = msalInstance.getActiveAccount();
  const tokenClaims = activeAccount?.idTokenClaims;
  if (tokenClaims) {
    const activeUserName =
      (tokenClaims['given_name'] as string) + ' ' + (tokenClaims['family_name'] as string) || '';
    return activeUserName;
  }
  return 'unknown';
};

// Translate meeting into client-side representation
const _getClientMeeting = (serverMeeting: MeetingServer): Meeting => {
  return {
    id: serverMeeting.id,
    worker: _getActiveUserName(),
    client: serverMeeting.client,
    dateTime: new Date(serverMeeting.dateTime), // Convert into JS Date object for easier formatting
    location: serverMeeting.location,
    meetingTemplateId: serverMeeting.formId,
    status: serverMeeting.status,
    createdAt: new Date(serverMeeting.createdAt),
    title: serverMeeting.title
  };
};

// Create a new meeting
export const createMeeting = async (meeting: CreateMeetingRequest): Promise<Meeting> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(meeting)
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const serverMeeting: MeetingServer = await response.json();
  const clientMeeting = _getClientMeeting(serverMeeting);
  return clientMeeting;
};

// Retrieve all meetings for the current user
export const getMeetings = async (): Promise<Meeting[]> => {
  const activeUserName = _getActiveUserName();
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const serverMeetings: MeetingServer[] = await response.json();

  const meetings = serverMeetings.map((serverMeeting: MeetingServer): Meeting => {
    return _getClientMeeting(serverMeeting);
  });

  return meetings;
};

// Retrieve all meetings for the current user and given client id
export const getMeetingsByClientId = async (clientId: number): Promise<Meeting[]> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations?client_id=${clientId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const serverMeetings: MeetingServer[] = await response.json();

  const meetings = serverMeetings.map((serverMeeting: MeetingServer): Meeting => {
    return _getClientMeeting(serverMeeting);
  });

  return meetings;
};

// Retrieve a meeting by id. Meeting === Conversation
export const getMeetingById = async (id: number): Promise<Meeting> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new ApiError(await response.text(), response.status);
  }

  const serverMeeting: MeetingServer = await response.json();
  const meeting = _getClientMeeting(serverMeeting);

  return meeting;
};

export const updateMeetingById = async (
  id: number,
  location?: string,
  dateTime?: string,
  clientId?: number,
  title?: string
): Promise<Meeting> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ location, dateTime, clientId, title })
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};

// Retrieve all custom questions for a meeting by meetingId
export const getCustomQuestionsByMeetingId = async (
  meetingId: number
): Promise<TemplateBlock[]> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/questions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};

// Retrieve all answers for a meeting by id
export const getAnswersByMeetingId = async (meetingId: number): Promise<TemplateBlockAnswer[]> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/answers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const serverAnswers: TemplateBlockAnswerServer[] = await response.json();

  const answers = serverAnswers.map((answer) => {
    return {
      id: answer.id,
      answer: answer.answer,
      templateBlockId: answer.formElementId,
      meetingId: answer.conversationId,
      type: answer.type
    };
  });

  return answers;
};

// Retrieve a meeting's transcript
export const getTranscriptByMeetingId = async (meetingId: number): Promise<TranscriptChunk[]> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/transcript`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const transcriptConversation = await response.json();
  return transcriptConversation.transcript;
};

// Process a recording and use it to generate answers for a meeting
export const processMeeting = async (meetingId: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/process`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: formData
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return true;
};

export const triggerProcessMeeting = async (meetingId: number, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  fetch(`${apiBaseUrl}/conversations/${meetingId}/process`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: formData
  });
};

// Retrieve the transcript indices for answer sources
export const getSourceCitationIndices = async (
  conversationId: number,
  query: string
): Promise<number[]> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${conversationId}/cite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ query })
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const transcriptChunks = await response.json();
  const indices = transcriptChunks.map((chunk: TranscriptChunk) => chunk.position);
  // Sort the indices in ascending order
  indices.sort((a: number, b: number) => a - b);
  return indices;
};

// Get the processing status of a meeting
export const getMeetingStatus = async (meetingId: number): Promise<ProcessingStatus> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/status`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  const unpackedResponse = await response.json();
  return unpackedResponse.status;
};

export const deleteMeetingById = async (meetingId: number): Promise<void> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
};

// Share meeting notes via email to a list of recipients
export const shareMeetingNotes = async (meetingId: number, emails: string[]): Promise<void> => {
  const accessToken: string = await getAccessToken();

  for (const email of emails) {
    const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/share`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ to: email, method: 'email' })
    });

    if (!response.ok) {
      throw new Error(`${await response.text()}`);
    }
  }
};

// Creates a new question and answer for a meeting note
export const createQuestionAndAnswerForNote = async (
  meetingId: number,
  customQuestion: TemplateBlockCreate,
  answerValue: AnswerValue
): Promise<TemplateBlockAndAnswer> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/custom_questions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({ ...customQuestion, answerValue })
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  const questionAndAnswer = await response.json();
  const serverAnswer = questionAndAnswer.answer;

  // Convert answer data to client-side representation
  const answer: TemplateBlockAnswer = {
    id: serverAnswer.id,
    answer: serverAnswer.answer,
    templateBlockId: serverAnswer.formElementId,
    meetingId: serverAnswer.conversationId,
    type: serverAnswer.type
  };

  return { block: questionAndAnswer.question, answer };
};

export const processCustomQuestion = async (
  meetingId: number,
  question: TemplateBlockCreate
): Promise<AnswerValue> => {
  const accessToken: string = await getAccessToken();
  const response = await fetch(`${apiBaseUrl}/conversations/${meetingId}/query`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(question)
  });
  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
  const answerData: { conversationId: number; answer: AnswerValue; type: TemplateElements } =
    await response.json();

  return await answerData.answer;
};
