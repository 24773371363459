import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMeetingById, processMeeting } from '../clients/meetingClient';
import { getTemplateAndBlocksById } from '../clients/templateClient';
import useNavigation from '../common/navigation';
import { ProcessingStatus } from '../common/types';
import { formatDateAs12HourTimeString, formatDateAsLongDate } from '../common/utils/dateUtils';
import AudioRecorder from '../components/AudioRecorder';
import ErrorBanner from '../components/ErrorBanner';
import LoadingState from '../components/LoadingState';
import { ButtonState } from '../components/MeetingListItem';
import Sidebar from '../components/Sidebar';
import UploadButton from '../components/UploadButton';
import { useGlobalContext } from '../context/GlobalProvider';

export default function RecordAudioPageExistingMeeting() {
  const [file, setFile] = useState<File | null>();
  const { state, setState } = useGlobalContext();
  const [isJustProcessed, setIsJustProcessed] = useState(false);

  const params = useParams();
  const meetingId = Number(params.meetingId);

  const { goToNotePage } = useNavigation();

  // Get meeting by id
  const {
    data: meeting,
    error,
    isLoading
  } = useQuery({
    queryKey: ['getMeetingById', meetingId],
    queryFn: () => getMeetingById(meetingId)
  });

  const { data: template, error: templateError } = useQuery({
    enabled: !!meeting,
    queryKey: ['template', meeting?.meetingTemplateId],
    queryFn: () => getTemplateAndBlocksById(meeting!.meetingTemplateId!)
  });

  const date = meeting ? formatDateAsLongDate(meeting.dateTime) : '';
  const time = meeting ? formatDateAs12HourTimeString(meeting.dateTime) : '';

  const displayData = [
    { key: 'Client', value: meeting?.client.name },
    { key: 'Date & Time', value: date + ' at ' + time },
    { key: 'Location', value: meeting?.location },
    { key: 'Meeting Type', value: template?.template.title }
  ];

  // Create meeting mutation that is triggered on button click
  const processMeetingMutation = useMutation({
    retry: 0,
    mutationFn: (args: { meetingId: number; file: File }) => {
      return processMeeting(args.meetingId, args.file);
    },
    onMutate: () => {
      // Set state to indicate processing begun for toast
      setState((prevState) => ({
        ...prevState,
        hasStartedProcessing: true
      }));
      meeting!.status = ProcessingStatus.PROCESSING;
    },
    onError: (_, args) => {
      // Set state to indicate processing error for toast
      setState((prevState) => ({
        ...prevState,
        processingError: true
      }));
      meeting!.status = ProcessingStatus.ERROR;
    },
    // If upload/processing succeeds, mark meeting as completed
    onSuccess: () => {
      // Set state to indicate processing completion for toast
      setState((prevState) => ({
        ...prevState,
        isDoneProcessing: true
      }));
      meeting!.status = ProcessingStatus.COMPLETED;
      setIsJustProcessed(true);
    }
  });

  const getOnClickBehavior = (buttonState: ButtonState) => {
    switch (buttonState) {
      case 'inAppRecording':
        return processMeetingMutation.mutate({ meetingId: meeting!.id!, file: file! });
      case 'processing':
        return {};
      case 'complete':
        return goToNotePage(meeting!.id!);
      default:
        return {};
    }
  };

  return (
    <div>
      <Sidebar currentPageName={'Meeting Notes'} />
      <main className="lg:ml-72">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-4  lg:w-3/4">
          {/* Heading */}
          <div className="border-b border-gray-200 pt-4 pb-4 mb-8">
            <h3 className="text-3xl font-bold">Record Meeting Audio</h3>
          </div>

          {error && error instanceof Error ? <ErrorBanner message={error.message} /> : null}

          {/* Display alert that meeting has already been processed ONLY if this is not the 
          first time that the user is triggering the audio recording processing */}
          {meeting?.status === ProcessingStatus.COMPLETED && !isJustProcessed ? (
            // <h2>This meeting already has a recording uploaded.</h2>
            <div className="rounded-md border border-indigo-200 bg-indigo-50 p-4 flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-5 w-5 text-indigo-700" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-indigo-800">
                  Recording audio has been successfully uploaded and processed.
                </p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <button
                    onClick={() => goToNotePage(meetingId)}
                    className="whitespace-nowrap font-medium text-indigo-700 hover:text-indigo-700"
                  >
                    View Notes
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </p>
              </div>
            </div>
          ) : (
            <div className="lg:px-8">
              <AudioRecorder
                setFile={setFile}
                handleAudio={() => {}}
                clearAudio={false}
                isDownloadAudioButtonShown={true}
              />

              {meeting && (
                <div className="flex justify-center mt-12">
                  <UploadButton
                    uploadDisabled={!file || !meeting}
                    getOnClickBehavior={getOnClickBehavior}
                    meetingState={meeting.status}
                    menuVersion={false}
                    className={'font-bold !text-sm !px-4 !py-2 text-white !shadow-xl'}
                  />

                  {/* <button
                    disabled={!file || !meeting}
                    type="submit"
                    className="flex items-center align-items gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none transition-color duration-300"
                    onClick={() =>
                      processMeetingMutation.mutate({ meetingId: meeting!.id!, file: file! })
                    }
                  >
                    Upload Recording
                    <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button> */}
                </div>
              )}
            </div>
          )}
          <div className="px-4 lg:px-8 mt-10 lg:mt-12">
            <h3 className="text-lg font-semibold leading-7 text-gray-900 mb-2">Meeting Details</h3>

            {isLoading ? (
              <LoadingState />
            ) : (
              <dl className="divide-y divide-gray-100">
                {displayData.map((item) => (
                  <div className="py-2.5 lg:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">{item.key}</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      {item.value}
                    </dd>
                  </div>
                ))}
              </dl>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
