import { apiBaseUrl } from '..';
import { TemplateBlock } from '../common/types';
import { getAccessToken } from '../common/utils/utils';

export const createTemplateBlock = async (
  templateId: number,
  templateBlock: TemplateBlock
): Promise<TemplateBlock> => {
  const response = await fetch(apiBaseUrl + `/templates/${templateId}/template_blocks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify(templateBlock)
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return await response.json();
};

export const updateTemplateBlockById = async (
  templateBlock: TemplateBlock
): Promise<TemplateBlock> => {
  const response = await fetch(apiBaseUrl + '/template_blocks/' + templateBlock.id, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    },
    body: JSON.stringify(templateBlock)
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return await response.json();
};

export const deleteTemplateBlockById = async (templateBlockId: number) => {
  const response = await fetch(apiBaseUrl + '/template_blocks/' + templateBlockId, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getAccessToken()}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return;
};
