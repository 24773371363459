import { Disclosure, Transition } from '@headlessui/react';
import { CheckCircleIcon, MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { classNames } from '../common/utils/utils';

interface CollapsibleNoteSectionProps {
  id: number;
  question: string;
  description: string;
  children: React.ReactNode;
  isApproved: boolean;
  setisApproved: (isApproved: boolean) => void;
}

export default function CollapsibleNoteSection({
  id,
  question,
  description,
  children,
  isApproved,
  setisApproved
}: CollapsibleNoteSectionProps) {
  return (
    <div>
      <Disclosure as="div" key={question} className="" defaultOpen={true}>
        {({ open, close }) => (
          <>
            <dt>
              <Disclosure.Button
                className={classNames(
                  // First collapsible section should have a rounded top border
                  id === 1 ? 'rounded-t-xl' : '',
                  'flex w-full items-start justify-between text-left text-gray-900 mx-auto p-5 font-medium rtl:text-right border border-gray-200 hover:bg-gray-100 gap-3 transition-bg duration-300'
                )}
              >
                {/* Section Header */}
                <div className="sm:w-0 sm:flex-1">
                  {/* Section Title */}
                  <h1
                    id="message-heading"
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    {question}
                  </h1>

                  {/* Section Subtitle */}
                  <p className="mt-2 truncate text-sm font-normal text-gray-500">{description}</p>
                </div>

                {/* Section Status Indicator */}
                {isApproved ? (
                  <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Approved
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-sm font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                    Needs Review
                  </span>
                )}

                {/* Section Expand/Collapse Icon */}
                <span className="ml-6 flex h-7 items-center">
                  {open ? (
                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </dt>

            {/* Section Body */}
            <Transition
              enter="transition ease duration-500 transform"
              enterFrom="opacity-0 -translate-y-12"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-12"
            >
              <Disclosure.Panel
                as="dd"
                className="py-8 px-12 border border-t-0 border-gray-200 space-y-5"
              >
                {children}

                {/* Approve Section Button */}
                <div className="flex flex-col items-center w-full ">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-2 mb-5 mt-10 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-bg duration-300"
                    onClick={() => {
                      setisApproved(true);
                      // close();
                    }}
                  >
                    Approve Section
                    <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
