import { CheckCircleIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import {
  createTemplateBlock,
  deleteTemplateBlockById,
  updateTemplateBlockById
} from '../clients/templateBlockClient';
import { updateTemplateById } from '../clients/templateClient';
import {
  QuestionParentType,
  Template,
  TemplateBlock as TemplateBlockType,
  TemplateElements,
  TemplateElementsWithLength,
  TemplateElementsWithOptions
} from '../common/types';
import { useGlobalContext } from '../context/GlobalProvider';
import useDebounce from '../hooks/useDebounce';
import useUnsavedChangesWarning from '../hooks/useUnsavedChangesWarning';
import AddQuestionMenu from './AddQuestionMenu';
import ErrorBanner from './ErrorBanner';
import LengthofList from './LengthofList';
import { LoadingIcon } from './LoadingIcon';
import {
  compareTemplateBlocks,
  doesBlockNeedLength,
  doesBlockNeedOptions,
  getButtonText
} from './MeetingTemplateBuilder.utils';
import OptionList from './OptionList';
import SaveStatusIndicator, { SaveStatus } from './SaveStatusIndicator';
import TemplateBlock from './TemplateBlock';
import TemplateSavedModal from './TemplateSavedModal';

const SAVE_DELAY = 1000;
const DEBOUNCE_DELAY = 1000;

export default function MeetingTemplateBuilder({
  isNewTemplate,
  template,
  blocks,
  isReadOnly
}: {
  isNewTemplate: boolean;
  template: Template;
  blocks: TemplateBlockType[];
  isReadOnly: boolean;
}) {
  // If template is new, set title to empty string so user can see placeholder text
  const title = isNewTemplate ? '' : template.title;

  const [initialTemplateTitle, setInitialTemplateTitle] = useState<string>(title);
  const [templateTitle, setTemplateTitle] = useState<string>(title);
  const [initialTemplateBlocks, setInitialTemplateBlocks] = useState<TemplateBlockType[]>(blocks);
  const [templateBlocks, setTemplateBlocks] = useState<TemplateBlockType[]>(blocks);
  const [uniqueBlockId, setUniqueBlockId] = useState<number>(0);
  const [mutationsCompleted, setMutationsCompleted] = useState({});
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.SAVED);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const anchorBlockRef = useRef<HTMLDivElement>(null); // For anchoring to and highlighting a newly created template block
  const [highlightIndex, setHighlightIndex] = useState<number | undefined>();

  const queryClient = useQueryClient();
  const { setState } = useGlobalContext();

  const hasChanges = () => {
    return (
      templateTitle !== initialTemplateTitle ||
      JSON.stringify(templateBlocks) !== JSON.stringify(initialTemplateBlocks)
    );
  };

  // Display confirmation window if user navigates away with unsaved changes
  useUnsavedChangesWarning(hasChanges);

  // When all expected mutations have succeeded,
  // open the success modal and reset initial states
  const handleSuccess = () => {
    // setIsSaveModalOpen(true);
    // Add an extra three seconds to the timeout to ensure the user sees the success message
    setTimeout(() => {
      setSaveStatus(SaveStatus.SAVED);
    }, SAVE_DELAY);

    setInitialTemplateTitle(templateTitle);
    setInitialTemplateBlocks(templateBlocks);
  };

  //=============================== MUTATIONS ==============================================
  const updateTemplateMutation = useMutation({
    mutationFn: ({
      templateId,
      newTemplateTitle
    }: {
      templateId: number;
      newTemplateTitle: string;
    }) => {
      return updateTemplateById(templateId, newTemplateTitle);
    },
    onMutate: ({
      templateId,
      newTemplateTitle
    }: {
      templateId: number;
      newTemplateTitle: string;
    }) => {
      template!.title = newTemplateTitle; // Update template title to reflect new title
      setMutationsCompleted((prev) => ({ ...prev, updateTemplateMutation: false }));

      // Optimisically update the template title
      queryClient.setQueryData(['getTemplateAndBlocksById', templateId], (prevData: any) => {
        return {
          ...prevData,
          template: {
            ...prevData.template,
            title: templateTitle
          }
        };
      });
    },
    onSuccess: () => {
      setMutationsCompleted((prev) => ({ ...prev, updateTemplateMutation: true }));
    },
    onError: (_, templateId) => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', templateId] });
    }
  });

  const createTemplateBlockMutation = useMutation({
    mutationFn: ({ templateId, block }: { templateId: number; block: TemplateBlockType }) => {
      return createTemplateBlock(templateId, block);
    },
    onMutate: (vars) => {
      setMutationsCompleted((prev) => ({
        ...prev,
        ['createTemplateBlock-' + vars.block.id]: false
      }));

      // Optimisically update template blocks with new block
      queryClient.setQueryData(['getTemplateAndBlocksById', vars.templateId], (prevData: any) => {
        return {
          ...prevData,
          blocks: [...prevData.blocks, vars.block]
        };
      });
    },
    onSuccess: (newTemplateBlock: TemplateBlockType, vars) => {
      // Update template block in state with new block id
      setTemplateBlocks((prevTemplateBlocks) =>
        prevTemplateBlocks.map((block) =>
          block.id === vars.block.id ? { ...block, id: newTemplateBlock.id } : block
        )
      );
      // Update query data with new block id
      queryClient.setQueryData(['getTemplateAndBlocksById', vars.templateId], (prevData: any) => {
        return {
          ...prevData,
          blocks: prevData.blocks.map((block: TemplateBlockType) =>
            block.id === vars.block.id ? { ...block, id: newTemplateBlock.id } : block
          )
        };
      });
      setMutationsCompleted((prev) => ({
        ...prev,
        ['createTemplateBlock-' + vars.block.id]: true
      }));
    },
    onError: (_1, vars) => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', vars.templateId] });
    }
  });

  const updateTemplateBlockMutation = useMutation({
    mutationFn: (templateBlock: TemplateBlockType) => {
      return updateTemplateBlockById(templateBlock);
    },
    onMutate: (templateBlock: TemplateBlockType) => {
      setMutationsCompleted((prev) => ({
        ...prev,
        ['updateTemplateBlockMutation-' + templateBlock.id]: false
      }));

      // Optimisically update template blocks with updated block
      queryClient.setQueryData(['getTemplateAndBlocksById', template!.id], (prevData: any) => {
        return {
          ...prevData,
          blocks: prevData.blocks.map((block: TemplateBlockType) =>
            block.id === templateBlock.id ? { ...templateBlock } : block
          )
        };
      });
    },
    onSuccess: (_, templateBlock: TemplateBlockType) => {
      setMutationsCompleted((prev) => ({
        ...prev,
        ['updateTemplateBlockMutation-' + templateBlock.id]: true
      }));
    },
    onError: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', template!.id] });
    }
  });

  const deleteTemplateBlockMutation = useMutation({
    mutationFn: (templateBlockId: number) => {
      return deleteTemplateBlockById(templateBlockId);
    },
    onMutate: (templateBlockId: number) => {
      setMutationsCompleted((prev) => ({
        ...prev,
        ['deleteTemplateBlockMutation-' + templateBlockId]: false
      }));

      // Optimisically update template blocks by removing deleted block
      const data = queryClient.getQueryData(['getTemplateAndBlocksById', template.id!]);
      if (data)
        queryClient.setQueryData(['getTemplateAndBlocksById', template!.id], (prevData: any) => {
          return {
            ...prevData,
            blocks: prevData.blocks.filter(
              (block: TemplateBlockType) => block.id !== templateBlockId
            )
          };
        });
    },
    onSuccess: (_, templateBlockId: number) => {
      setMutationsCompleted((prev) => ({
        ...prev,
        ['deleteTemplateBlockMutation-' + templateBlockId]: true
      }));
    },
    onError: () => {
      // Invalidate the query to refetch the data
      queryClient.invalidateQueries({ queryKey: ['getTemplateAndBlocksById', template!.id] });
    }
  });
  //=============================== END MUTATIONS ==========================================

  const hasError = () =>
    updateTemplateMutation.isError ||
    createTemplateBlockMutation.isError ||
    updateTemplateBlockMutation.isError ||
    deleteTemplateBlockMutation.isError;

  useEffect(() => {
    if (hasError()) {
      setSaveStatus(SaveStatus.ERROR);
    }
  }, [
    updateTemplateMutation.isError,
    createTemplateBlockMutation.isError,
    updateTemplateBlockMutation.isError,
    deleteTemplateBlockMutation.isError
  ]);

  const debouncedTitle = useDebounce(templateTitle, DEBOUNCE_DELAY);
  const debouncedBlocks = useDebounce(templateBlocks, DEBOUNCE_DELAY);

  useEffect(() => {
    // Do not continue attempting to save if an error has occurred
    if (hasChanges() && !hasError()) {
      handleSubmit();
    }

    // If user insists on continuing to make changes, continually
    // display error message each time they attempt to make a change
    if (hasError())
      setState((prevState) => ({
        ...prevState,
        templateBuilderError: true
      }));
  }, [debouncedTitle, debouncedBlocks]);

  // If all mutations that have been triggered are completed, open the success modal
  useEffect(() => {
    if (
      Object.keys(mutationsCompleted).length > 0 &&
      Object.values(mutationsCompleted).every((mutation) => mutation === true)
    ) {
      handleSuccess();
    }
  }, [mutationsCompleted]);

  const setBlockQuestionById = (id: number, question: string) => {
    setTemplateBlocks((prevTemplateBlocks) =>
      prevTemplateBlocks.map((block) => (block.id === id ? { ...block, question } : block))
    );
  };

  const setBlockOptionsById = (
    id: number,
    optArgs: { options?: string[]; lengthOfList?: number }
  ) => {
    setTemplateBlocks((prevTemplateBlocks) =>
      prevTemplateBlocks.map((block) => (block.id === id ? { ...block, optArgs } : block))
    );
  };

  /**
   * Triggered by "Add Element" menu. Adds a new template block based on
   * the template element menu item that was selected.
   */
  function addTemplateElement(elementType: TemplateElements) {
    if (!Object.values(TemplateElements).includes(elementType)) {
      alert('Invalid template element selected');
    } else {
      const newTemplateBlock = createTemplateBlockByType(elementType);
      // need to do this to update templateBlocks in an async-safe manner
      setTemplateBlocks((prevTemplateBlocks) => [...prevTemplateBlocks, newTemplateBlock]);
      // jump to new template block (which is always the last in the list)
      anchorBlockRef.current?.scrollIntoView({ behavior: 'smooth' });
      // Remove the highlight after a delay
      setHighlightIndex(newTemplateBlock.position); // replace with actual index
      setTimeout(() => setHighlightIndex(undefined), 5000); // 5 seconds
    }
  }

  /**
   * Triggered by clicking the delete button for a specific template block.
   * Removes said block from the list of template blocks.
   */
  function removeTemplateElementById(id: number) {
    // need to do this to update templateBlocks in an async-safe manner
    setTemplateBlocks((prevTemplateBlocks) => {
      if (!prevTemplateBlocks.some((block) => block.id === id)) {
        alert('Attempted to delete an invalid block. Please try again.');
      }
      return prevTemplateBlocks.filter((block) => block.id !== id);
    });
  }

  /**
   * Creates a TemplateBlock by:
   * 1. Building a TemplateBlock
   * 2. Adding an options list if needed
   * 3. Using the current uniqueBlockID, and then incrementing it
   */
  const createTemplateBlockByType = (elementType: TemplateElements) => {
    const newTemplateBlock: TemplateBlockType = {
      id: uniqueBlockId,
      question: '',
      type: elementType,
      position: templateBlocks.length,
      parentType: QuestionParentType.Template
    };

    // Add options list to elements that need it
    if (TemplateElementsWithOptions.includes(elementType)) {
      newTemplateBlock.optArgs = {};
      newTemplateBlock.optArgs.options = ['']; // Always start off with a single empty option
    }

    // Add length of list to elements that need it
    if (TemplateElementsWithLength.includes(elementType)) {
      newTemplateBlock.optArgs = {};
      newTemplateBlock.optArgs.lengthOfList = 3;
    }

    // Increment block ID for the next block
    setUniqueBlockId(uniqueBlockId + 1);
    return newTemplateBlock;
  };

  /**
   * - Makes API call to update template and template blocks
   * - Displays successful save modal on success
   */
  const handleUpdate = (templateId: number) => {
    setSaveStatus(SaveStatus.SAVING);

    if (templateTitle !== initialTemplateTitle)
      updateTemplateMutation.mutate({
        templateId,
        newTemplateTitle: templateTitle || 'Untitled Template'
      });

    // Preserve ordering of blocks by updating their positions
    const updatedTemplateBlocks = templateBlocks.map((block, index) => ({
      ...block,
      position: index
    }));
    setTemplateBlocks(updatedTemplateBlocks);

    // Compare initial template blocks with current template blocks
    // to determine which blocks need to be updated, created, or deleted
    const { newBlocks, updatedBlocks, deletedBlocks } = compareTemplateBlocks(
      initialTemplateBlocks,
      updatedTemplateBlocks
    );

    // Create new template blocks
    newBlocks.forEach((block: TemplateBlockType) => {
      createTemplateBlockMutation.mutate({ templateId, block });
    });

    // Update existing template blocks
    updatedBlocks.forEach((block: TemplateBlockType) => {
      updateTemplateBlockMutation.mutate(block);
    });

    // Delete removed template blocks
    deletedBlocks.forEach((block: TemplateBlockType) => {
      deleteTemplateBlockMutation.mutate(block.id);
    });
  };

  /**
   * Handle "Save Meeting Template" button click.
   * - Prevents form submission from refreshing page
   * - Makes API call to create template and template blocks
   * - Marks template as being saved
   * - Displays successful save modal on success
   */
  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    // Prevent page from refreshing
    e?.preventDefault();
    handleUpdate(template.id!);
  };

  const hasMutationsPending =
    updateTemplateMutation.isPending ||
    createTemplateBlockMutation.isPending ||
    updateTemplateBlockMutation.isPending ||
    deleteTemplateBlockMutation.isPending;

  // Use this to trigger the delay when hasMutationsPending changes
  useEffect(() => {
    if (hasMutationsPending) {
      setShowLoadingIcon(true);
    } else {
      const timer = setTimeout(() => setShowLoadingIcon(false), SAVE_DELAY);
      return () => clearTimeout(timer); // Cleanup the timer if `hasMutationsPending` changes
    }
  }, [hasMutationsPending]);

  const isLastBlock = (index: number) => index === templateBlocks.length - 1;

  return (
    <div className="py-8 xl:px-8 flex flex-grow flex-col lg:flex-row bg-white">
      <div className="w-full lg:w-1/3 ">
        {/* Element Menu */}
        {!isReadOnly && <AddQuestionMenu addTemplateElement={addTemplateElement} />}
      </div>

      <div className="lg:w-2/3 justify-between lg:order-first">
        {/* Template Title */}
        <form onSubmit={handleSubmit}>
          {/* Template Title */}
          <input
            className="w-3/4 pb-2 text-xl mt-8 lg:mt-0 mb-12 text-gray-600 font-medium outline-none border-b border-gray-300 focus:text-gray-800 focus:border-indigo-600 focus:border-b-2 transition-border duration-300"
            type="text"
            placeholder="Type Meeting Template Title Here"
            value={templateTitle}
            onChange={(e) => setTemplateTitle(e.target.value)}
            disabled={isReadOnly}
            onBlur={(e) => setTemplateTitle(e.target.value.trim())}
            required
          />

          {/* Template Block Section */}
          <ul id="template-builder" className=" space-y-10 ">
            {/* Empty State */}
            {templateBlocks.length === 0 ? (
              <div className="relative block w-full rounded-lg p-16 text-center border border-dashed border-gray-300">
                <DocumentPlusIcon className="mx-auto h-7 w-7 stroke-1 text-gray-400" />
                <span className="mt-2 block text-sm text-gray-500 text-normal">
                  Start building your template using the "Add a Question" menu!
                </span>
              </div>
            ) : (
              templateBlocks.map((block, index) => {
                return (
                  <li key={index}>
                    <TemplateBlock
                      index={index}
                      ref={isLastBlock(index) ? anchorBlockRef : null}
                      question={block.question}
                      setQuestion={(newQuestion) => setBlockQuestionById(block.id, newQuestion)}
                      templateElementType={block.type}
                      deleteBlock={() => removeTemplateElementById(block.id)}
                      isReadOnly={isReadOnly}
                      isHighlighted={highlightIndex === block.position}
                    >
                      {/* Option List for Elements that need it */}
                      {doesBlockNeedOptions(block) ? (
                        <OptionList
                          options={block.optArgs!.options!}
                          setOptions={(options: string[]) =>
                            setBlockOptionsById(block.id, { options })
                          }
                          isReadOnly={isReadOnly}
                        />
                      ) : null}

                      {/* Length of list input for elements that need it */}
                      {doesBlockNeedLength(block) ? (
                        <LengthofList
                          lengthOfList={block.optArgs?.lengthOfList}
                          onChange={(lengthOfList: number) =>
                            setBlockOptionsById(block.id, { lengthOfList })
                          }
                          isReadOnly={isReadOnly}
                        />
                      ) : null}
                    </TemplateBlock>
                  </li>
                );
              })
            )}
          </ul>

          {/* Save Template Button */}
          {/* {templateBlocks.length === 0 && !hasChanges() ? null : ( // Only hide button when creating new template and no blocks have been added */}
          <div className="flex justify-center w-full mt-10">
            <button
              type="submit"
              className="flex items-center align-items gap-x-2 mt-8 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none transition-color duration-300"
              disabled={
                (templateBlocks.length === 0 && !hasChanges()) ||
                hasMutationsPending ||
                !hasChanges() ||
                isReadOnly
              } // Disabled if there are no template blocks, or mutation is in action, or no changes have been made
            >
              {getButtonText(showLoadingIcon, hasChanges())}

              {/* Button SVG */}
              {!showLoadingIcon ? (
                <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <LoadingIcon />
              )}
            </button>
          </div>
          {/* )} */}
        </form>
        {/* Error when attempting to update existing template */}
        {updateTemplateMutation.error && (
          <ErrorBanner message={updateTemplateMutation.error.message} />
        )}
        {/* Error when attempting to create new template block */}
        {createTemplateBlockMutation.error && (
          <ErrorBanner message={createTemplateBlockMutation.error.message} />
        )}
        {/* Error when attempting to update existing template block */}
        {updateTemplateBlockMutation.error && (
          <ErrorBanner message={updateTemplateBlockMutation.error.message} />
        )}
        {/* Error when attempting to delete existing template block */}
        {deleteTemplateBlockMutation.error && (
          <ErrorBanner message={deleteTemplateBlockMutation.error.message} />
        )}
        <TemplateSavedModal open={isSaveModalOpen} setOpen={setIsSaveModalOpen} />
        {!isReadOnly && <SaveStatusIndicator status={saveStatus} />}
      </div>
    </div>
  );
}
