import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getIsTemplateUsed, getTemplateAndBlocksById } from '../clients/templateClient';
import { ApiError } from '../common/types';
import ErrorBanner from '../components/ErrorBanner';
import LoadingState from '../components/LoadingState';
import MeetingTemplateBuilder from '../components/MeetingTemplateBuilder';
import ProductTourWrapper from '../components/ProductTourWrapper';
import Sidebar from '../components/Sidebar';
import { useProductTourContext } from '../context/ProductTourContext';
import NotFoundPage from './NotFoundPage';

export default function MeetingTemplatePage() {
  const templateId = Number(useParams().templateId);
  const location = useLocation();
  const isNewTemplate = location.state?.isNewTemplate;

  // ============================= PRODUCT TOUR ===============================
  const { setState: setTourState, state: tourState } = useProductTourContext();

  const pauseTour = () => setTourState({ ...tourState, run: false });
  const resumeTour = () => setTourState({ ...tourState, run: true });

  // On mount, if tour is active, trigger the first step for the clients page
  useEffect(() => {
    if (tourState.tourActive) {
      pauseTour();
      const timer = setTimeout(() => resumeTour(), 600); // ms delay to ensure the tour is active before setting the step index.
      return () => clearTimeout(timer);
    }
  }, []);
  // =========================== END PRODUCT TOUR ===============================

  // Load template data if templateId is defined
  const {
    error: templateError,
    data: templateData,
    isLoading: isTemplateLoading
  } = useQuery({
    enabled: !!templateId,
    queryKey: ['getTemplateAndBlocksById', templateId],
    queryFn: () => getTemplateAndBlocksById(templateId),
    retry: false
  });

  // Get usage status of template
  const { data, isLoading: isTemplateUsedLoading } = useQuery({
    enabled: !!templateId && !isNewTemplate,
    queryKey: ['isTemplateUsed', templateId],
    queryFn: () => getIsTemplateUsed(templateId)
  });
  const isTemplateUsed = data && data.hasBeenUsed;

  if (templateError instanceof ApiError && templateError.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Meeting Templates'} />

      <main className="lg:pl-72">
        {isTemplateLoading ||
          (isTemplateUsedLoading ? (
            <LoadingState />
          ) : (
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-12">
              {/* Heading */}
              <div className="border-b border-gray-200 flex items-center pt-4 pb-6">
                <h3 className="text-3xl font-bold text-gray-800">
                  {isNewTemplate
                    ? 'Build a New Meeting Template'
                    : isTemplateUsed
                      ? 'View Meeting Template'
                      : 'View or Edit Meeting Template'}
                </h3>
                {!isNewTemplate && isTemplateUsed ? (
                  <div className=" ml-4 bg-teal-100 w-fit h-fit text-teal-800 rounded-full bg-teal-50 px-2 py-1 text-xs flex items-center font-medium text-teal-700 ring-1 ring-inset ring-teal-600/20">
                    In Use
                    {/* Tooltip */}
                    <div className="text-left relative group">
                      <QuestionMarkCircleIcon
                        className="h-4 w-4 ml-1 -mr-1 text-green-800 opacity-30 hover:stroke-2 hover:opacity-70 transition-opacity transition-stroke duration-300"
                        aria-hidden="false"
                      />
                      <span className="absolute block bottom-full bg-gray-500 left-8 top-0 h-fit w-72 text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                        <p>
                          This template has been used to generate notes. It cannot be edited or
                          deleted.
                        </p>
                        <p className="mt-2">
                          If you need to make changes, you can duplicate this template and make
                          edits to the duplicate.
                        </p>
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              {templateError && <ErrorBanner message={templateError.message} />}

              {/* Meeting Template Builder */}
              <MeetingTemplateBuilder
                isNewTemplate={isNewTemplate}
                template={templateData?.template}
                blocks={templateData?.blocks}
                isReadOnly={isTemplateUsed}
              />
            </div>
          ))}
      </main>
    </ProductTourWrapper>
  );
}
