type ProgressBarProps = {
  steps: number; // Total number of steps
  activeStep: number; // Current active step (0-indexed)
};

// Continuous Progress Bar
// const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
//   const progress = (activeStep / steps) * 100;

//   return (
//     <div className="flex items-center justify-center space-x-4 w-full">
//       <div className="flex items-center w-9/12">
//         <div className="flex-1 h-1 bg-gray-300 relative">
//           <div
//             style={{ width: `${progress}%` }}
//             className="h-full bg-indigo-700 rounded transition-all"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// Dots
const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
  return (
    <div className="justify-between">
      {Array.from({ length: steps }).map((_, dotIndex) => (
        <span
          key={dotIndex}
          className={`h-[7px] w-[7px] mx-[3px] rounded-full inline-block transition-colors duration-300 ${
            dotIndex === activeStep ? 'bg-indigo-700' : 'bg-gray-300'
          }`}
        />
      ))}
    </div>
  );
};

// Stepped Progress Bar
// const ProgressBar = ({ steps, activeStep }: ProgressBarProps) => {
//   return (
//     <div className="flex items-center justify-center space-x-4 w-full">
//       <div className="flex items-center w-9/12">
//         {/* Stepped Progress Bar */}
//         <div className="flex w-full space-x-1">
//           {Array.from({ length: steps }).map((_, index) => (
//             <div
//               key={index}
//               className={`flex-1 h-1 rounded ${
//                 index <= activeStep ? 'bg-indigo-700' : 'bg-gray-300'
//               }`}
//             />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

export default ProgressBar;
