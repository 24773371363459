// ---------------------------------------------------------
// For wrapping all pages that are part of the product tour
// ---------------------------------------------------------

import Joyride, { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { To, useNavigate } from 'react-router-dom';
import { useProductTourContext } from '../context/ProductTourContext';
import { steps, stepsWithModal } from '../data/productTourSteps';
import CustomTooltip from './CustomJoyrideTooltip';

export default function ProductTourWrapper({ children }: { children: React.ReactNode }) {
  const { setState, state } = useProductTourContext();
  const navigate = useNavigate();

  const handleCallback = (data: CallBackProps) => {
    const { action, index, step, type } = data;
    const next: To = step.data?.next;
    const previous: To = step.data?.previous;

    // console.log('event fired', type, action, index, step);

    // If user clicks a button to go to next step, update the
    // step index and navigate to the proper page, if relevant
    if (type === EVENTS.STEP_AFTER) {
      const nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      // If the next step requires a modal, we need to trigger opening the modal
      // before moving to the step that is anchored to/targets the modal.
      if (stepsWithModal.includes(nextIndex)) {
        setState({ ...state, shouldModalOpen: true });
        setTimeout(() => {
          setState((prevState) => ({ ...prevState, stepIndex: nextIndex }));
        }, 200);
      } else {
        setState((prevState) => ({ ...prevState, shouldModalOpen: false, stepIndex: nextIndex }));
      }

      // If user clicks Finish button, terminate tour
      if (action === ACTIONS.NEXT && index === steps.length - 1) {
        setState((prevState) => ({ ...prevState, tourActive: false, run: false }));
      }

      // If we are navigating to another page, stop running the tour and give the next page some
      // time to load. That page will be responsible for re-starting the tour.
      const willNavigate =
        (action === ACTIONS.PREV && previous) || (action === ACTIONS.NEXT && next);
      if (willNavigate) {
        setState((prevState) => ({ ...prevState, run: false }));
      }

      const nextPage = action === ACTIONS.PREV ? previous : next;
      // if (nextPage) console.log('attempting to navigate to', nextPage);
      navigate(nextPage);
    }

    if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
      setState({ ...state, tourActive: false, run: false });
    }
  };

  return (
    <>
      {children}
      <Joyride
        callback={handleCallback}
        continuous={true}
        locale={{
          back: 'Back', // Customize "Back" button label
          close: 'Close', // Customize "Close" button label
          last: 'Finish', // Customize label for the last button (instead of "Next")
          next: 'Next', // Customize "Next" button label
          skip: 'Skip Tutorial' // Customize "Skip" button label
        }}
        run={state.run}
        // run={false}
        // showProgress={true}
        stepIndex={state.stepIndex}
        steps={steps}
        tooltipComponent={CustomTooltip}
      />
    </>
  );
}
