import { TemplateBlockAnswer, TemplateBlockAnswerServer } from '../common/types';
import { getAccessToken } from '../common/utils/utils';
import { apiBaseUrl } from '../index';

// Update answer by id
export const updateAnswerById = async (
  answer: TemplateBlockAnswer
): Promise<TemplateBlockAnswer> => {
  const accessToken: string = await getAccessToken();

  const serverSideAnswer: TemplateBlockAnswerServer = {
    id: answer.id,
    answer: answer.answer,
    formElementId: answer.templateBlockId,
    conversationId: answer.meetingId,
    type: answer.type
  };

  const response = await fetch(`${apiBaseUrl}/answers/${answer.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(serverSideAnswer)
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }

  return response.json();
};

// Delete answer by id
export const deleteAnswerById = async (answerId: number, questionId: number): Promise<void> => {
  const accessToken: string = await getAccessToken();

  const response = await fetch(`${apiBaseUrl}/template_blocks/${questionId}/answers/${answerId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`${await response.text()}`);
  }
};
