import { PlusIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { TemplateElementKey, TemplateElements } from '../common/types';
import { getReadableString } from '../common/utils/utils';
import { templateElementDescription } from './MeetingTemplateBuilder.utils';

export default function AddQuestionMenu({
  addTemplateElement
}: {
  addTemplateElement: (templateElementType: TemplateElements) => void;
}) {
  return (
    <div
      id="add-question-menu"
      className="flex lg:flex-col flex-wrap lg:fixed right-0 mr-4 xl:mr-12 bg-white border border-gray-200 rounded-lg p-6 px-4 xl:px-8 min-w-60 justify-left"
    >
      <h3 className="w-full text-lg font-semibold text-gray-700">
        Add a Question
        <span className="flex font-normal text-sm">by clicking one of these options:</span>
      </h3>
      <ul className="w-full flex flex-col">
        {Object.keys(TemplateElements).map((key, index) => {
          const templateElementType = TemplateElements[key as TemplateElementKey];
          return (
            <li
              key={index}
              className="cursor-pointer rounded-md py-1 px-4 font-medium bg-indigo-50 hover:bg-indigo-500 text-indigo-800 hover:text-white flex items-center text-sm mt-4 transition-color w-fit duration-300"
              onClick={() => addTemplateElement(templateElementType)}
            >
              <PlusIcon className="mr-1.5 -ml-1 h-4 w-4" />
              <span>{getReadableString(templateElementType)}</span>
              {/* Tooltip */}
              <div className="text-left relative group">
                <QuestionMarkCircleIcon
                  className="h-4 w-4 ml-1 -mr-1 text-white-800 opacity-30 hover:opacity-70 transition-opacity transition-stroke duration-300"
                  aria-hidden="false"
                />
                <span className="absolute block bottom-full bg-gray-500 left-0 top-5 h-fit w-36 text-white px-2 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                  {templateElementDescription(templateElementType)}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
