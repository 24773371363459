// GlobalContext.tsx
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface GlobalState {
  isDoneProcessing: boolean;
  hasStartedProcessing: boolean;
  templateDeletionError: boolean;
  processingError: boolean;
  templateDuplicationError: boolean;
  templateUpdateError: boolean;
  templateBuilderError: boolean;
  templateCreationError: boolean;
  meetingDeletionSuccess: boolean;
  meetingDeletionError: boolean;
  templateDeletionSuccess: boolean;
  meetingCreationSuccess: boolean;
  meetingCreationError: boolean;
  meetingUpdateSuccess: boolean;
  meetingUpdateError: boolean;
  clientCreationSuccess: boolean;
  clientCreationError: boolean;
  shareNotesSuccess: boolean;
  shareNotesError: boolean;
  createCustomQuestionError: boolean;
  createCustomQuestionSuccess: boolean;
  processCustomQuestionError: boolean;
  deleteCustomQuestionSuccess: boolean;
  deleteCustomQuestionError: boolean;
  // Add more state variables as needed
}

interface GlobalContextProps {
  state: GlobalState;
  setState: React.Dispatch<React.SetStateAction<GlobalState>>;
  // Add any functions you want to provide globally
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<GlobalState>({
    hasStartedProcessing: false,
    isDoneProcessing: false,
    templateDeletionError: false,
    processingError: false,
    templateDuplicationError: false,
    templateUpdateError: false,
    templateBuilderError: false,
    templateCreationError: false,
    meetingDeletionSuccess: false,
    meetingDeletionError: false,
    templateDeletionSuccess: false,
    meetingCreationSuccess: false,
    meetingCreationError: false,
    meetingUpdateSuccess: false,
    meetingUpdateError: false,
    clientCreationSuccess: false,
    clientCreationError: false,
    shareNotesSuccess: false,
    shareNotesError: false,
    createCustomQuestionError: false,
    createCustomQuestionSuccess: false,
    processCustomQuestionError: false,
    deleteCustomQuestionSuccess: false,
    deleteCustomQuestionError: false
    // Initialize other state variables
  });

  const value = {
    state,
    setState
    // Add any functions you want to provide globally
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = (): GlobalContextProps => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};
