import { Step } from 'react-joyride';

/**
 * Sometimes we need to take specific actions for certain steps.
 * Unfortunately, Joyride doesn't provide a way to do this dynamically,
 * so we need to hardcode the step indices.
 */
export const clientsPageStepIndex = 4;
// export const addClientStepIndex = 6;
export const templatesPageStepIndex = 7;
export const templateBuilderStepIndex = 9;
export const revisitTemplatesPageStepIndex = 10;
export const createNewTemplateStepIndex = 11;
export const meetingNotesPageStepIndex = 13;
export const addMeetingStepIndex = 14;
export const notePageStepIndex = 17;

/**
 * For steps that anchor to a modal, we must open the modal before
 * moving to that step.
 */
export const stepsWithModal: number[] = [
  // addClientStepIndex,
  addMeetingStepIndex
];

export const steps: Step[] = [
  // ============================== Home Page =================================
  // index: 0
  {
    title: 'Welcome to Notewell 👋',
    content: (
      <h2>
        We'd like to show you around! Remember, you can always replay this tutorial by clicking the
        tutorial button on the top right of the home page.
      </h2>
    ),
    placement: 'center',
    target: 'body',
    disableOverlayClose: true
  },
  // index: 1
  {
    title: 'How it works',
    content: (
      <>
        <ol className="list-decimal mt-3 ml-8 space-y-1 marker:font-bold">
          <li className="pl-2">
            You add a <strong>client</strong>.
          </li>
          <li className="pl-2">
            You build a <strong>meeting template</strong> to capture the questions you want answered
            from your meeting.
          </li>
          <li className="pl-2">
            You <strong>record a conversation</strong> with your client and upload the recording to
            Notewell.
          </li>
          <li className="pl-2">
            Notewell <strong>fills out notes</strong> based on the questions you added in your
            meeting template ✨
          </li>
        </ol>
        <p className="mt-3"></p>
      </>
    ),
    placement: 'center',
    target: 'body',
    disableOverlayClose: true
  },
  // index: 2
  {
    title: 'Easy Navigation',
    content:
      'These buttons let you quickly access your clients, notes, and templates from the home page. (You can also access them from the sidebar menu on the left)',
    target: '#linkContainer',
    disableOverlayClose: true
  },
  // index: 3
  {
    title: 'Adding a Client',
    content: "Let's check out the clients page!",
    target: '#home-clients-link',
    disableOverlayClose: true,
    data: {
      next: '/clients'
    }
  },
  // ============================= Clients Page ===============================
  // index: 4 - clientsPageStepIndex
  {
    title: 'Your Clients',
    content:
      "Welcome to the clients page - we've started you off with an example client named Jane Doe.",
    target: '#clients-list',
    disableOverlayClose: true,
    data: {
      previous: '/'
    }
  },
  // index: 5
  {
    title: 'Add a Client',
    content: "When you're ready, click here to add your clients.",
    target: '#add-client-button',
    disableOverlayClose: true
  },
  // index: 6
  {
    title: 'Meeting Templates',
    content: "Now let's take a look at meeting templates!",
    target: '#templates-link',
    disableOverlayClose: true,
    placement: 'right',
    data: {
      next: '/meeting-templates'
    }
  },
  // ============================ Templates Page ==============================
  // index: 7 - templatesPageStepIndex
  {
    target: 'body',
    title: 'Meeting Templates',
    content: (
      <p>
        A meeting template is made of <strong> questions that you want answered </strong>during a
        meeting. Notewell will automatically generate answers to each of these questions from a
        recorded meeting.
      </p>
    ),
    placement: 'center',
    disableOverlayClose: true,
    data: {
      previous: '/clients'
    }
  },
  // index: 8
  {
    target: '#first-template',
    title: 'Meeting Templates',
    content: (
      <>
        <p>
          You can create
          <span className="font-bold"> meeting templates</span> for your different meeting types,
          like intake, goal-setting, check-in, and more.
        </p>
        <br />
        <p>Here's a sample goal-setting meeting template. Let's take a look!</p>
      </>
    ),
    placement: 'top',
    disableOverlayClose: true
  },
  // ========================= Template Builder Page ==========================
  // index: 9 - templateBuilderStepIndex
  {
    target: '#template-builder',
    title: 'Meeting Templates',
    content: (
      <>
        <p>Here are some questions you might have in a goal-setting template.</p>
        <br />
        <p>
          <em>
            (You may have noticed that each question is labeled with a different "type" - we'll
            explain these soon)
          </em>
        </p>
      </>
    ),
    placement: 'top-end',
    disableOverlayClose: true,
    data: {
      previous: '/meeting-templates',
      next: '/meeting-templates'
    }
  },
  // index: 10 - revisitTemplatesPageStepIndex
  {
    title: 'Building a Meeting Template',
    content: "Now let's see how to build a template from scratch.",
    target: '#create-template-button',
    disableOverlayClose: true
  },
  // index: 11
  {
    target: '#add-question-menu',
    title: 'Adding a Question',
    content: (
      <>
        <p>
          There are different types of questions (similar to Google Forms). You can hover over the
          question marks to read about each type.
        </p>
        <p className="mt-2">
          To add a question to your template, click on one of these options and then type in your
          question.
        </p>
      </>
    ),
    disableOverlayClose: true,
    spotlightClicks: true,
    placement: 'left',
    data: {
      previous: '/meeting-templates'
    }
  },
  // index: 12
  {
    target: '#notes-link',
    title: 'Meeting Notes',
    content: <p>Now let's look at some meeting notes!</p>,
    disableOverlayClose: true,
    data: {
      next: '/meeting-notes'
    }
  },
  // ========================== Meeting Notes Page ============================
  // index: 13 - meetingNotesPageStepIndex
  {
    target: '#add-meeting-button',
    title: 'Meeting Notes',
    content: (
      <>
        <p>
          This page is where you can see all of your <strong>meeting notes</strong> - meeting notes
          are the <strong>answers to the questions in your meeting templates</strong> that Notewell
          has filled out using your recordings.
        </p>
        <p className="mt-2">
          To make a meeting note, click <em>Add Meeting</em>.
        </p>
      </>
    ),
    disableOverlayClose: true,
    data: {
      previous: '/meeting-templates'
    }
  },
  // index: 14 - addMeetingStepIndex
  {
    target: '#add-meeting-modal',
    title: 'Adding a Meeting',
    disableOverlayClose: true,
    spotlightClicks: true,
    content: (
      <p>
        In the future, when you add a meeting note, select the relevant client and meeting template
        for your meeting note.
      </p>
    )
  },
  // index: 15
  {
    target: '#upload-button',
    title: 'Generating Meeting Notes',
    content:
      'When you have a meeting with a client, record it and upload the recording here. Then, Notewell will process it and create notes.',
    disableOverlayClose: true
  },
  // index: 16
  {
    target: '#first-meeting',
    title: 'Meeting Notes',
    content: "Here's what a completed meeting note looks like. Let's take a look 👀",
    disableOverlayClose: true
  },
  // ========================== Meeting Note Page =============================
  // index: 17 - notePageStepIndex
  {
    target: '#note-body',
    title: 'Meeting Notes',
    content:
      '✨ This is where the magic happens ✨ Notewell has populated answers to the Goal-Setting meeting template that we saw earlier.',
    placement: 'top-end',
    disableOverlayClose: true,
    data: {
      previous: '/meeting-notes'
    }
  },
  // index: 18
  {
    target: '#template-link',
    title: 'Meeting Notes',
    content: 'To refer to the meeting template you built for these notes, click here.',
    disableOverlayClose: true
  },
  // index: 19
  {
    target: '#transcript-column',
    title: 'Transcript',
    content:
      'A transcript of the conversation is on the right 👉. You can search it for specific words 💬',
    placement: 'left',
    disableOverlayClose: true,
    spotlightClicks: true
  },
  // index: 20
  {
    target: '#first-block',
    title: 'Editing Content',
    content:
      'You can edit any of the answers. Save your changes using the save button at the bottom ✏️',
    disableOverlayClose: true
  },
  // index: 21
  {
    target: '#followup-question',
    title: 'Follow-up Questions',
    content:
      "If you have further questions, ask them here and they'll be answered right away. (These will be added to your meeting notes and will not affect your meeting template)",
    disableOverlayClose: true
  },
  // index: 22
  {
    target: 'body',
    title: 'The End',
    content:
      "That's all, folks! 🫡 We hope you enjoy using Notewell. If you have any questions, don't hesitate to reach out to us at team@notewell.ai",
    placement: 'center',
    disableOverlayClose: true
  }
];
