// utils.ts
import { msalInstance } from '../..';
import { protectedResources } from '../../authconfig';
import {
  Meeting,
  TemplateBlock,
  TemplateBlockAndAnswer,
  TemplateBlockAnswer,
  TemplateElements
} from '../types';

/**
 * Returns the access token from the MSAL instance
 */
export const getAccessToken = async (): Promise<string> => {
  const request = {
    scopes: [protectedResources.notewellServerApi.scopes[0]]
  };
  const response = await msalInstance.acquireTokenSilent(request);
  return response.accessToken;
};

export const classNames = (...classes: (string | boolean | undefined)[]) =>
  classes.filter(Boolean).join(' ');

export const getInitials = (fullName: string): string => {
  const names = fullName.split(' ');
  const initials = names.map((name) => name.charAt(0).toUpperCase()).join('');
  return initials;
};

/**
 * Converts enum values into a readable strings
 */
export const getReadableString = (templateElement: TemplateElements): string => {
  switch (templateElement) {
    case TemplateElements.SingleSelect:
      return 'Single-Select';
    case TemplateElements.MultiSelect:
      return 'Multi-Select';
    case TemplateElements.ShortAnswer:
      return 'Short Answer';
    case TemplateElements.ShortAnswerList:
      return 'Short Answer List';
    case TemplateElements.Paragraph:
      return 'Paragraph ';
    case TemplateElements.Date:
      return 'Date';
    case TemplateElements.Time:
      return 'Time';
  }
};

/**
 * Combines arrays of template blocks and answers together
 * into an object {block, answer}
 */
export const zipTemplateBlocksAndAnswers = (
  blocks: TemplateBlock[],
  answers: TemplateBlockAnswer[]
): TemplateBlockAndAnswer[] => {
  return (
    blocks
      // Combine blocks with their answers
      .map((block: TemplateBlock) => {
        const answer = answers.find(
          (answer: TemplateBlockAnswer) => block.id === answer.templateBlockId
        );
        return { block, answer: answer };
      })
  );
};

/**
 * Sorts a list of meetings. If descending = true, sorts from future to past.
 * If false, sorts from past to future
 */
export const sortMeetings = (meetings: Meeting[], descending = true) =>
  meetings.sort((a: Meeting, b: Meeting) => {
    const dateTimeA = a.dateTime.getTime();
    const dateTimeB = b.dateTime.getTime();
    const createdAtA = a.createdAt!.getTime();
    const createdAtB = b.createdAt!.getTime();

    if (dateTimeA === dateTimeB) {
      // If dateTime is the same, use createdAt as tiebreaker
      return descending ? createdAtB - createdAtA : createdAtA - createdAtB;
    }

    return descending ? dateTimeB - dateTimeA : dateTimeA - dateTimeB;
  });
