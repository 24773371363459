import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Dialog, Transition } from '@headlessui/react';
import { UserCircleIcon as UserCircleIconSolid } from '@heroicons/react/20/solid';
import {
  ArrowLeftCircleIcon,
  Bars3Icon,
  CalendarIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UserCircleIcon as UserCircleIconOutline,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import useNavigation from '../common/navigation';
import { classNames } from '../common/utils/utils';
import logo from '../images/logo_vertical.png';

export default function Sidebar({ currentPageName }: { currentPageName: string }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { instance } = useMsal();
  const activeAccount: AccountInfo | null = instance.getActiveAccount();
  const tokenClaims = activeAccount?.idTokenClaims;
  let activeUserName = 'unknown';
  if (tokenClaims) {
    activeUserName =
      (tokenClaims['given_name'] as string) + ' ' + (tokenClaims['family_name'] as string) || '';
  }

  const { goToHomePage, goToClientsPage, goToNotesPage, goToTemplatesPage } = useNavigation();
  const navigation = [
    { name: 'Home', onClick: goToHomePage, icon: HomeIcon, current: false, id: 'home-link' },
    {
      name: 'Clients',
      onClick: goToClientsPage,
      icon: UserCircleIconOutline,
      current: false,
      id: 'clients-link'
    },
    {
      name: 'Meeting Notes',
      onClick: goToNotesPage,
      icon: CalendarIcon,
      current: false,
      id: 'notes-link'
    },
    {
      name: 'Meeting Templates',
      onClick: goToTemplatesPage,
      icon: DocumentDuplicateIcon,
      current: true,
      id: 'templates-link'
    }
    // { name: 'Reports', href: '/reports', icon: ChartPieIcon, current: false }
  ];

  const setCurrentPage = (currentPageName: string) => {
    // Set the current page to be active in the sidebar
    for (let i = 0; i < navigation.length; i++) {
      if (navigation[i].name === currentPageName) {
        navigation[i].current = true;
      } else {
        navigation[i].current = false;
      }
    }
  };
  setCurrentPage(currentPageName);

  return (
    <div className="print:hidden">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <button onClick={goToHomePage} className="flex h-24 shrink-0 items-center">
                    <img
                      className="h-14 mt-4 w-auto"
                      // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      src={logo}
                      alt="Notewell Logo"
                    />
                  </button>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name} id={item.id}>
                              <button
                                onClick={item.onClick}
                                className={classNames(
                                  item.current
                                    ? 'bg-indigo-50 text-indigo-700'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-indigo-50 transition-color duration-300',
                                  'group flex w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? 'text-indigo-600'
                                      : 'text-gray-400 group-hover:text-indigo-600 transition-color duration-300',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
          <button onClick={goToHomePage} className="flex flex-col shrink-0 items-center">
            <img
              className="h-[68px] w-auto mt-12 mb-4"
              // src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              src={logo}
              alt="Notewell Logo"
            />
          </button>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name} id={item.id}>
                      <button
                        onClick={item.onClick}
                        className={classNames(
                          item.current
                            ? 'bg-indigo-50 text-indigo-800'
                            : 'text-gray-700 hover:text-indigo-600 hover:bg-indigo-50 transition-color duration-300',
                          'group flex w-full gap-x-3 rounded-md p-2 text-base leading-6 font-semibold'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-indigo-600'
                              : 'text-gray-400 group-hover:text-indigo-600 transition-color duration-300',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto">
                <button
                  className="w-full flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-500 hover:bg-indigo-50 hover:cursor-pointer hover:text-gray-800 transition-color duration-300"
                  // className="text-gray-700"
                  onClick={() => {
                    instance.logoutRedirect();
                  }}
                >
                  <ArrowLeftCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  Logout
                </button>
                <div className="flex items-center gap-x-4 px-6 py-4 text-base font-semibold leading-6 text-gray-700">
                  {/* <img className="h-8 w-8 rounded-full bg-gray-50" src={profilePic} alt="" /> */}
                  <UserCircleIconSolid className="h-6 w-6 rounded-full bg-gray-50 text-gray-400" />
                  <span className="sr-only">Your profile</span>
                  <span aria-hidden="true">{activeUserName} ☃️</span>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-10 flex items-center gap-x-4 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          <button
            onClick={goToHomePage}
            className="w-fit hover:text-indigo-700 hover:bg-indigo-50 px-1.5 py-2 rounded transition-color duration-300"
          >
            Home
          </button>
        </div>
        <a>
          <span className="sr-only">Your profile</span>
          {/* <img className="h-8 w-8 rounded-full bg-gray-50" src={profilePic} alt="" /> */}
          <UserCircleIconSolid className="h-8 w-8 rounded-full bg-gray-50 text-gray-400" />
        </a>
        <button
          className="-ml-3 text-gray-700 text-sm font-semibold text-gray-600 hover:text-indigo-700 hover:bg-indigo-50 px-3 py-2 rounded transition-color duration-300"
          onClick={() => {
            instance.logoutRedirect();
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
