import { PlusIcon } from '@heroicons/react/20/solid';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import {
  createTemplateAndBlocks,
  deleteTemplateById,
  duplicateTemplateById,
  getTemplates,
  updateTemplateById
} from '../clients/templateClient';
import useNavigation from '../common/navigation';
import { Template, TemplateBlockCreate } from '../common/types';
import EmptyState from '../components/EmptyState';
import ErrorBanner from '../components/ErrorBanner';
import { LoadingIcon } from '../components/LoadingIcon';
import LoadingState from '../components/LoadingState';
import ProductTourWrapper from '../components/ProductTourWrapper';
import Sidebar from '../components/Sidebar';
import TemplateListItem from '../components/TemplateListItem';
import { useGlobalContext } from '../context/GlobalProvider';
import { useProductTourContext } from '../context/ProductTourContext';
import { createNewTemplateStepIndex, templateBuilderStepIndex } from '../data/productTourSteps';

export const sortByUpdatedAtDescending = (templates: Template[]) =>
  templates.sort(
    (a: Template, b: Template) =>
      new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime()
  );

export default function MeetingTemplatesPage() {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({}); // false if not loaded, true if loaded
  const [allChildrenLoaded, setAllChildrenLoaded] = useState(false); // For checking if all templates' statuses have loaded in children components

  const { setState } = useGlobalContext();
  const { goToTemplatePage } = useNavigation();

  // ================================ PRODUCT TOUR ==========================================
  const { setState: setTourState, state: tourState } = useProductTourContext();
  const buttonRef = useRef<HTMLButtonElement>(null); // For navigating to template builder during product tour
  const createTemplateButtonRef = useRef<HTMLButtonElement>(null); // For navigating to new template during product tour

  const pauseTour = () => setTourState({ ...tourState, run: false });
  const resumeTour = () => setTourState({ ...tourState, run: true });

  // On mount, if tour is active, trigger the first step for the clients page
  useEffect(() => {
    if (tourState.tourActive) {
      pauseTour();
      const timer = setTimeout(() => resumeTour(), 600); // ms delay to ensure the tour is active before setting the step index.
      return () => clearTimeout(timer);
    }
  }, []);

  // If user used product tour to navigate to the template builder, trigger clicking button to
  // navigate to template view
  useEffect(() => {
    if (tourState.tourActive && tourState.stepIndex === templateBuilderStepIndex) {
      setTourState({ ...tourState, run: false });
      buttonRef.current?.click();
    }
  }, [tourState.stepIndex]);

  // If user used product tour to navigate to create a new template, trigger clicking button to
  // navigate to new template view
  useEffect(() => {
    if (tourState.tourActive && tourState.stepIndex === createNewTemplateStepIndex) {
      setTourState({ ...tourState, run: false });
      createTemplateButtonRef.current?.click();
    }
  }, [tourState.stepIndex]);
  // ================================ END PRODUCT TOUR ======================================

  // Fetch templates
  const {
    data: templatesData,
    error: templatesError,
    isLoading: isTemplatesLoading
  } = useQuery({
    queryKey: ['getTemplates'],
    queryFn: getTemplates
  });

  // Only update templates if data from getTemplates has changed, and is non-null
  useEffect(() => {
    if (templatesData) {
      const sortedTemplates = sortByUpdatedAtDescending(templatesData);
      setTemplates(sortedTemplates);
    }
  }, [templatesData]);

  useEffect(() => {
    // Initialize loading states for each template once all templates have been loaded
    // Only do this once, otherwise it will reset the loading states every time the
    // templates are re-fetched
    if (templatesData && !isTemplatesLoading && Object.keys(loadingStates).length === 0) {
      templatesData.forEach((template: Template) => {
        setLoadingStates((prevState) => ({
          ...prevState,
          [template.id!]: false
        }));
      });
    }
  }, [isTemplatesLoading]);

  useEffect(() => {
    // Need to check if templates are loading, because we don't want to set allChildrenLoaded to true
    // just because loadingStates has not been populated yet (and is still an empty object)
    if (!isTemplatesLoading && Object.values(loadingStates).every((state) => state === true)) {
      setAllChildrenLoaded(true);
    } else {
      setAllChildrenLoaded(false);
    }
  }, [loadingStates]);

  // Update loading state for specific template iff it exists in loadingStates
  // We check for pre-existing membership specifically so that we don't add any
  // temporary template ids to the dictionary
  const handleLoadingStateChange = (templateId: number, state: boolean) => {
    setLoadingStates((prevState) =>
      templateId in prevState ? { ...prevState, [templateId]: state } : prevState
    );
  };

  const optimisticallyDuplicateTemplate = (templateId: number, tempDuplicateId: number) => {
    const originalTemplate = templates.find((template) => template.id === templateId);

    if (!originalTemplate) {
      return;
    }

    const now = new Date().toISOString();

    const duplicateTemplate: Template = {
      ...originalTemplate,
      id: tempDuplicateId,
      title: `Copy of ${originalTemplate.title}`,
      createdAt: now,
      updatedAt: now
    };

    const updatedTemplates = [...templates, duplicateTemplate];
    const sortedTemplates = sortByUpdatedAtDescending(updatedTemplates);
    setTemplates(sortedTemplates);
  };

  //  ================================ MUTATIONS ==============================================
  // Create meeting mutation that is triggered on button click
  const createTemplateMutation = useMutation({
    mutationFn: ({
      templateData,
      templateBlockData
    }: {
      templateData: Template;
      templateBlockData: TemplateBlockCreate[];
    }) => {
      return createTemplateAndBlocks({ templateData, templateBlockData });
    },
    onSuccess: (data) => {
      goToTemplatePage(data.templateId, true);
    },
    onError: () => {
      setState((prevGlobalState) => ({ ...prevGlobalState, templateCreationError: true }));
    }
  });

  const duplicateTemplateMutation = useMutation({
    mutationFn: (originalTemplateId: number) => {
      return duplicateTemplateById(originalTemplateId);
    },
    onMutate: (originalTemplateId: number) => {
      // Optimistically update template list with new template
      const temporaryDuplicateId = Math.floor(Math.random() * 1000000);
      optimisticallyDuplicateTemplate(originalTemplateId, temporaryDuplicateId);
      return { temporaryDuplicateId };
    },
    onSuccess: (newTemplate: Template, _, context) => {
      // Get temporary ID and replace it with the actual ID
      const tempDuplicateId = context.temporaryDuplicateId;
      const updatedTemplates = templates.map((template) =>
        template.id === tempDuplicateId ? newTemplate : template
      );
      setTemplates(updatedTemplates);
    },
    onError: (_1, _2, context) => {
      // Set the global state to indicate that the template duplication failed for toast
      setState((prevGlobalState) => ({ ...prevGlobalState, templateDuplicationError: true }));

      // Revert optimistic update
      const tempDuplicateId = context?.temporaryDuplicateId;
      const updatedTemplates = templates.filter((template) => template.id !== tempDuplicateId);
      setTemplates(updatedTemplates);
    }
  });

  const updateTemplateMutation = useMutation({
    mutationFn: ({ templateId, title }: { templateId: number; title: string }) => {
      return updateTemplateById(templateId, title);
    },
    onSuccess: (updatedTemplate: Template) => {
      const updatedTemplates = templates.map((template) =>
        template.id === updatedTemplate.id ? updatedTemplate : template
      );
      setTemplates(updatedTemplates);
    },
    onError: () => {
      // Display error toast
      setState((prevGlobalState) => ({ ...prevGlobalState, templateUpdateError: true }));
    }
  });

  /**
   * Triggered by clicking the delete button for a specific template.
   * Performs optimistic update by removing template from list of templates.
   */
  const deleteTemplateMutation = useMutation({
    mutationFn: (templateId: number) => {
      return deleteTemplateById(templateId);
    },
    onMutate: (variables) => {
      // Optimistic update: remove template from templates list
      const deletedTemplate = templates.find((template) => template.id === variables);
      // need to do this to update templateBlocks in an async-safe manner
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.id !== variables)
      );
      return { deletedTemplate };
    },
    onSuccess: () => {
      // Display toast to indicate that the template was successfully deleted
      setState((prevGlobalState) => ({ ...prevGlobalState, templateDeletionSuccess: true }));
    },
    onError: (_1, _2, context) => {
      // If deletion fails, we need to re-add the template
      if (context?.deletedTemplate)
        setTemplates((prevTemplates) =>
          sortByUpdatedAtDescending([context.deletedTemplate as Template, ...prevTemplates])
        );

      // Set the global state to indicate that the template deletion failed for toast
      setState((prevGlobalState) => ({ ...prevGlobalState, templateDeletionError: true }));
    }
  });
  //  ================================ END MUTATIONS ==========================================

  const handleCreateTemplate = () => {
    // Create template
    createTemplateMutation.mutate({
      templateData: {
        title: 'Untitled Template'
      },
      templateBlockData: []
    });
  };

  const handleUpdateTemplate = (
    templateId: number,
    title: string,
    setIsTitleEditing: (isEditing: boolean) => void
  ) => {
    updateTemplateMutation.mutate(
      { templateId, title },
      {
        onSuccess: () => setIsTitleEditing(false)
      }
    );
  };

  const handleDuplicateTemplate = (templateId: number) => {
    duplicateTemplateMutation.mutate(templateId);
  };

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Meeting Templates'} />
      <main className="lg:pl-72">
        {(isTemplatesLoading || !allChildrenLoaded) && (
          <div className="fixed w-full h-full bg-white z-10">
            <LoadingState />
          </div>
        )}
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-12">
          {/* Heading */}
          <div className="border-b border-gray-200 pt-4 pb-6 mb-8">
            <h3 className="text-3xl font-bold text-gray-800">Your Meeting Templates</h3>
          </div>

          {/* If there was an error retrieving templates */}
          {templatesError && <ErrorBanner message={templatesError.message} />}
          {/* If there was an error in deleting a template */}
          {deleteTemplateMutation.error && (
            <ErrorBanner message={deleteTemplateMutation.error.message} />
          )}

          {/* Empty State */}
          {templates.length === 0 ? (
            <EmptyState
              icon={DocumentIcon}
              title="No meeting templates"
              description="Get started by creating your first meeting template!"
              buttonText="Create Template"
              onClick={handleCreateTemplate}
            />
          ) : (
            // If templates exist
            <div>
              {/* New Meeting Template Button */}
              <div className="flex w-full mb-4">
                <button
                  ref={createTemplateButtonRef}
                  id="create-template-button"
                  onClick={handleCreateTemplate}
                  type="button"
                  className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                >
                  {createTemplateMutation.isPending ? (
                    <LoadingIcon className="mr-2" />
                  ) : (
                    <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  )}
                  Create Template
                </button>
              </div>

              {/* Meeting Template Cards */}
              {/* <ul className="grid grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"> */}
              <ul role="list" className="divide-y divide-gray-100 xl:w-3/4 p-2 overflow-x-clip">
                {templates.map((template) => (
                  <TemplateListItem
                    ref={buttonRef}
                    isUsedInProductTour={template.title === 'Goal-Setting Meeting'} // Reference to seeded meeting template for product tour
                    key={template.id}
                    template={template}
                    onLoadingStateChange={handleLoadingStateChange}
                    deleteTemplate={(templateId: number) =>
                      deleteTemplateMutation.mutate(templateId)
                    }
                    duplicateTemplate={handleDuplicateTemplate}
                    updateTemplate={handleUpdateTemplate}
                  />
                ))}
              </ul>
            </div>
          )}
        </div>
      </main>
    </ProductTourWrapper>
  );
}
