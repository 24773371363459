import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {} from '@heroicons/react/24/outline';
import { UseMutationResult, useQuery } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { getClients } from '../clients/clientClient';
import { getTemplates } from '../clients/templateClient';
import { Client, CreateMeetingRequest, LanguageCode, Meeting, Template } from '../common/types';
import {
  combineDateAndTime,
  formatDateAs24HourTimeString,
  formatDateAsYMD,
  roundDownToNearestQuarterHour
} from '../common/utils/dateUtils';
import ErrorBanner from './ErrorBanner';
import { LoadingIcon } from './LoadingIcon';

const getCurrentYMDDate = () => {
  const today = new Date();
  return formatDateAsYMD(today);
};

const getCurrent24HTimeRoundedToLastQuarterHour = () => {
  const today = new Date();
  const todayRoundedToLastHalfHour = roundDownToNearestQuarterHour(today);
  return formatDateAs24HourTimeString(todayRoundedToLastHalfHour);
};

interface AddMeetingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isCurrent: boolean; // If true, defaults date and time to now and hides those inputs
  createMeetingMutation: UseMutationResult<Meeting, Error, CreateMeetingRequest, unknown>;
  buttonText?: string; // Override button text
  overrideSubmit?: (e: React.FormEvent<HTMLFormElement>, newMeeting: CreateMeetingRequest) => void; // Override default submission behavior
  overrideSubmitError?: Error;
  goBack?: () => void;
}

export default function AddMeetingModal({
  isOpen,
  setIsOpen,
  isCurrent,
  createMeetingMutation,
  buttonText,
  overrideSubmit,
  overrideSubmitError,
  goBack
}: AddMeetingModalProps) {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [clientId, setClientId] = useState<number>();
  const [date, setDate] = useState(getCurrentYMDDate());
  const [time, setTime] = useState(getCurrent24HTimeRoundedToLastQuarterHour());
  const [location, setLocation] = useState('');
  const [meetingTemplateId, setMeetingTemplateId] = useState<number>();
  const [primaryLanguage, setPrimaryLanguage] = useState<LanguageCode>(LanguageCode.ENGLISH);
  const [isSubmitting, setIsSubmitting] = useState(false); // Triggered by form submission, so not part of mutation state (otherwise we'd use mutation.isLoading)

  // Convert the enum to an array of objects
  const languageOptions = Object.entries(LanguageCode).map(([label, value]) => ({
    label: label.charAt(0).toUpperCase() + label.slice(1).toLowerCase(), // Capitalize the label
    value
  }));

  const clearState = () => {
    // Clear state
    setClientId(undefined);
    setDate(getCurrentYMDDate());
    setTime(getCurrent24HTimeRoundedToLastQuarterHour());
    setLocation('');
    setMeetingTemplateId(undefined);
    setPrimaryLanguage(LanguageCode.ENGLISH);
  };

  // Fetch templates to render for selection in meeting creation form
  const { data: templateData, error: templateError } = useQuery({
    queryKey: ['getTemplates'],
    queryFn: getTemplates
  });

  // Fetch clients for current user
  const { data: clients, error: clientsError } = useQuery({
    queryKey: ['getClients'],
    queryFn: getClients
  });

  useEffect(() => {
    if (templateData) {
      setTemplates(templateData);
    }
  }, [templateData]);

  const getNewMeeting = (): CreateMeetingRequest => {
    return {
      clientId: clientId!,
      dateTime: isCurrent ? new Date().toISOString() : combineDateAndTime(date, time).toISOString(), // API expects a single Date obj representing date and time
      location,
      formId: meetingTemplateId!,
      primaryLanguage
    };
  };

  /**
   * On form submission,
   * 1. prevent window from refreshing
   * 2. trigger create meeting mutation with data from form
   * 3. on success, clear state. regardless, set isSubmitting to false to reactivate button
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    e.preventDefault();
    const newMeeting = getNewMeeting();
    createMeetingMutation.mutate(newMeeting, {
      onSuccess: () => {
        clearState();
        // Set a delay to prevent button from becoming active too quickly
        setTimeout(() => {
          setIsSubmitting(false);
        }, 1000);
      },
      onError: () => {
        setIsSubmitting(false);
      }
    });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={
          !goBack
            ? () => {
                setIsOpen(false);
                createMeetingMutation.reset();
              }
            : () => {}
        }
      >
        {/* Grayed-out background */}
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        {/* Core Modal */}
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                id="add-meeting-modal"
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-12"
              >
                {/* Go Back Button */}
                {goBack && (
                  <>
                    <button
                      type="button"
                      className="absolute top-6 left-6 text-gray-400 hover:text-indigo-600 focus:outline-none transition-color duration-300"
                      onClick={goBack}
                    >
                      <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="absolute top-6 right-6 text-gray-400 hover:text-indigo-600 focus:outline-none transition-color duration-300"
                      onClick={() => setIsOpen(false)}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </>
                )}

                <div>
                  <div className="text-center">
                    {/* Modal title */}
                    <DialogTitle as="h3" className="text-xl font-bold leading-6 text-gray-900">
                      Meeting Details
                    </DialogTitle>
                    {/* If there was an error retrieving templates */}
                    {templateError && <ErrorBanner message={templateError.message} />}
                    {/* If error retriecing clients */}
                    {clientsError && <ErrorBanner message={clientsError.message} />}
                    {/* Error creating meeting */}
                    {createMeetingMutation.error && (
                      <ErrorBanner message={createMeetingMutation.error.message} />
                    )}
                    {overrideSubmitError && <ErrorBanner message={overrideSubmitError.message} />}

                    {/* Modal body */}
                    <div className="mt-8 sm:mx-auto sm:w-full">
                      <form
                        className="space-y-4"
                        onSubmit={(e) => {
                          overrideSubmit ? overrideSubmit(e, getNewMeeting()) : handleSubmit(e);
                        }}
                      >
                        <div>
                          <label className="block text-left text-sm font-medium leading-6 text-gray-900">
                            Client Name
                          </label>
                          <div className="mt-1">
                            <select
                              id="client"
                              name="client"
                              defaultValue={''}
                              onChange={(e) => {
                                setClientId(Number(e.target.value));
                              }}
                              required
                              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="" disabled>
                                Select a client
                              </option>
                              {clients?.map((client: Client) => (
                                <option key={client.name} value={client.id}>
                                  {client.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Meeting Template
                          </label>
                          <div className="mt-1">
                            <select
                              id="meeting-type"
                              name="meeting-type"
                              defaultValue={''}
                              onChange={(e) => {
                                setMeetingTemplateId(Number(e.target.value));
                              }}
                              required
                              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="" disabled>
                                Select a meeting template
                              </option>
                              {templates.map((template) => (
                                <option key={template.title} value={template.id}>
                                  {template.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {!isCurrent && (
                          <>
                            <div>
                              <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                                Date
                              </label>
                              <div className="mt-1">
                                <input
                                  id="date"
                                  name="date"
                                  type="date"
                                  value={date}
                                  onChange={(e) => setDate(e.target.value)}
                                  required
                                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div>
                              <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                                Time
                              </label>
                              <div className="mt-1">
                                <input
                                  id="time"
                                  name="time"
                                  type="time"
                                  value={time}
                                  onChange={(e) => setTime(e.target.value)}
                                  required
                                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div>
                          <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Location{' '}
                            <span className="font-light text-gray-500 italic">- Optional</span>
                          </label>
                          <div className="mt-1">
                            <input
                              id="location"
                              name="location"
                              type="text"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              onBlur={(e) => setLocation(e.target.value.trim())} // Remove leading/trailing whitespace
                              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div>
                          <label className="text-left block text-sm font-medium leading-6 text-gray-900">
                            Primary Language{' '}
                            <span className="font-light text-gray-500 italic">- Optional</span>
                          </label>
                          <div className="mt-1">
                            <select
                              id="language"
                              name="language"
                              defaultValue={LanguageCode.ENGLISH}
                              onChange={(e) => setPrimaryLanguage(e.target.value as LanguageCode)}
                              className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option value="" disabled>
                                Select primary language used in meeting
                              </option>
                              {languageOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div>
                          <button
                            disabled={isSubmitting}
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 mt-12 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 disabled:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            {buttonText ?? 'Add Meeting'}
                            {isSubmitting && <LoadingIcon className="ml-2" />}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
