import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ArrowUpTrayIcon, CheckCircleIcon, MicrophoneIcon } from '@heroicons/react/24/outline';
import { ProcessingStatus } from '../common/types';
import { classNames } from '../common/utils/utils';
import { ButtonState } from './MeetingListItem';
import SpinningIcon from './SpinningIcon';

const getButtonClassnames = (meetingState: ProcessingStatus, providedClassname?: string) => {
  const sharedClassnames =
    'duration-300 font-semibold inline-flex rounded-md text-xs transition-color whitespace-nowrap disabled:cursor-not-allowed';
  let specificClassnames = '';
  switch (meetingState) {
    case ProcessingStatus.NOT_STARTED:
    case ProcessingStatus.ERROR:
      specificClassnames =
        'bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600 focus-visible:outline-offset-2 gap-x-2 hover:bg-indigo-500 hover:text-white px-3.5 py-2 shadow-sm text-indigo-800 disabled:bg-indigo-50 disabled:text-indigo-800';
      break;
    case ProcessingStatus.PROCESSING:
      specificClassnames =
        'bg-indigo-500 items-center leading-6 px-3 py-1 shadow text-white transition';
      break;
    case ProcessingStatus.COMPLETED:
      specificClassnames =
        'bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600 focus-visible:outline-offset-2 gap-x-2 hover:bg-indigo-500 hover:text-white pl-3.5 pr-2.5 py-2 shadow-sm text-white';
      break;
    default:
      break;
  }
  return classNames(specificClassnames, sharedClassnames, providedClassname);
};

export default function UploadButton({
  meetingState,
  getOnClickBehavior,
  menuVersion,
  uploadDisabled,
  className
}: {
  getOnClickBehavior: (buttonState: ButtonState) => void;
  meetingState: ProcessingStatus;
  menuVersion: boolean;
  uploadDisabled?: boolean;
  className?: string;
}) {
  return (
    <>
      {(meetingState == ProcessingStatus.NOT_STARTED ||
        meetingState === ProcessingStatus.ERROR) && (
        <>
          {menuVersion ? (
            <Menu as="div" className="relative flex-none w-fit">
              <MenuButton className={getButtonClassnames(meetingState, className)}>
                <span className="sr-only">Open options</span>
                Add Recording
                {/* <EllipsisVerticalIcon aria-hidden="true" className="h-4 w-4" /> */}
              </MenuButton>
              <MenuItems
                transition
                className="absolute font-normal right-0 z-10 w-36 px-1.5 origin-top-right text-xs font-semibold rounded-md bg-white text-gray-600 data-[focus]:text-gray-900 py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                  <button
                    onClick={() => getOnClickBehavior('needsUpload')}
                    className="inline-flex items-center block w-full pl-3 py-1 leading-6 data-[focus]:text-indigo-700 data-[focus]:bg-indigo-50"
                  >
                    <ArrowUpTrayIcon className="h-3.5 w-3.5 mr-2" />
                    Upload<span className="sr-only"></span>
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    onClick={() => getOnClickBehavior('inAppRecording')}
                    className="inline-flex items-center block w-full px-3 py-1 leading-6 data-[focus]:text-indigo-700 data-[focus]:bg-indigo-50"
                  >
                    <MicrophoneIcon className="h-3.5 w-3.5 mr-2" />
                    Record In-App<span className="sr-only"></span>
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          ) : (
            !uploadDisabled && (
              <button
                disabled={uploadDisabled}
                className={getButtonClassnames(meetingState, className + ' bg-indigo-600')}
                onClick={() => getOnClickBehavior('inAppRecording')}
              >
                Generate notes using audio <ArrowUpTrayIcon className="h-4 w-4" />
              </button>
            )
          )}
        </>
      )}
      {meetingState === ProcessingStatus.PROCESSING && (
        <button
          disabled
          className={getButtonClassnames(meetingState, className)}
          onClick={() => getOnClickBehavior('processing')}
        >
          <SpinningIcon />
          <span className="ml-3">Processing...</span>
        </button>
      )}
      {meetingState === ProcessingStatus.COMPLETED && (
        <button
          className={getButtonClassnames(meetingState, className)}
          onClick={() => getOnClickBehavior('complete')}
        >
          View Notes <CheckCircleIcon className="h-4 w-4" />
        </button>
      )}
    </>
  );
}
