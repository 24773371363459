// ====================================================================================================
// =================================== Utils for Time and Dates =======================================
// ====================================================================================================
/**
 * Takes in a date "YYYY-MM-DD" and a time "HH:MM"
 * and combines them into a single Date object
 */
export const combineDateAndTime = (date: string, time: string): Date => {
  // Validate date format (YYYY-MM-DD)
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(date)) {
    throw new Error('Invalid date format. Please use YYYY-MM-DD format.');
  }

  // Validate time format (HH:MM)
  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeRegex.test(time)) {
    throw new Error('Invalid time format. Please use HH:MM format.');
  }

  // Combine date and time into a single string
  const dateTimeString = `${date}T${time}:00`; // e.g., "2024-05-28T14:30:00"

  // Create a Date object
  return new Date(dateTimeString);
};

// Converts a locale-aware date string (YYYY-MM-DD) into a readable date string (DayOfWeek, Month DD, YYYY)
export const formatYMDAsLongDateWithWeekday = (dateString: string): string => {
  const [year, month, day] = dateString.split('-');
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  return formatDateAsLongDateWithWeekday(date);
};

// Formats date object as Month DD, YYYY
export const formatDateAsLongDate = (date: Date): string => {
  return date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

// Formats date object as DayOfWeek, Month DD, YYYY
export const formatDateAsLongDateWithWeekday = (date: Date): string => {
  return date.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};

// Formats a Date object as a date string yyyy/mm/dd
// This is the format expected by HTML date input. It is also the only format
// that is both natively sortable and can be used as a key in a map
export const formatDateAsYMD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Formats a Date object as a time string HH:MM
export const formatDateAs24HourTimeString = (date: Date): string => {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
};

// Formats a Date object as a time string HH:MM AM/PM
export const formatDateAs12HourTimeString = (date: Date): string => {
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

// Returns true if date is in the past, false if in the future
export const isPast = (date: Date): boolean => {
  const now = new Date();
  return date < now;
};

// Generates a string representing the relative time since the given date
// e.g., "3 days ago", "just now", etc.
export function timeSince(date: Date): string {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 2) return `${interval} years ago`;
  if (interval === 1) return '1 year ago';
  interval = Math.floor(seconds / 2592000);
  if (interval >= 2) return `${interval} months ago`;
  if (interval === 1) return '1 month ago';
  interval = Math.floor(seconds / 86400);
  if (interval >= 2) return `${interval} days ago`;
  if (interval === 1) return '1 day ago';
  interval = Math.floor(seconds / 3600);
  if (interval >= 2) return `${interval} hours ago`;
  if (interval === 1) return '1 hour ago';
  interval = Math.floor(seconds / 60);
  if (interval >= 2) return `${interval} minutes ago`;
  if (interval === 1) return '1 minute ago';
  return 'just now';
}

// Takes a date and rounds the time down to the nearest quarter hour.
// For example, 12:14 -> 12:00, and 12:20 -> 12:15.
export const roundDownToNearestQuarterHour = (date: Date): Date => {
  const quarterHour = 15 * 60 * 1000; // 15 minutes in milliseconds
  return new Date(Math.floor(date.getTime() / quarterHour) * quarterHour);
};
