import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getClientById } from '../clients/clientClient';
import { getMeetingsByClientId } from '../clients/meetingClient';
import { ApiError } from '../common/types';
import { sortMeetings } from '../common/utils/utils';
import ActionItems from '../components/ActionItems';
import ClientFeedItem from '../components/ClientFeedItem';
import ErrorBanner from '../components/ErrorBanner';
import LoadingState from '../components/LoadingState';
import Sidebar from '../components/Sidebar';
import RecordAudioButton from '../RecordAudioButton';
import NotFoundPage from './NotFoundPage';

const HARDCODED_CLIENT_WITH_ACTION_ITEMS = 2;

export default function ClientPage() {
  // get client ID from URL param
  const params = useParams();
  const clientId = Number(params.clientId);

  // Load client by id
  const {
    data: client,
    error: clientError,
    isLoading: isClientLoading
  } = useQuery({
    queryKey: ['getClientById', clientId],
    queryFn: () => getClientById(clientId)
  });

  // Get conversations for given client
  const {
    data: meetings,
    error: meetingsError,
    isLoading: isConversationsLoading
  } = useQuery({
    queryKey: ['getMeetingsByClientId', clientId],
    queryFn: () => getMeetingsByClientId(clientId)
  });

  // sort meetings in descending order
  const sortedMeetings = meetings ? sortMeetings(meetings, true) : undefined;

  if (isClientLoading || isConversationsLoading)
    return (
      <>
        <Sidebar currentPageName={'Clients'} />
        <main className="lg:pl-72 flex items-center">
          <LoadingState />
        </main>
      </>
    );

  if (clientError instanceof ApiError && clientError.status === 404) {
    return <NotFoundPage />;
  }

  return (
    <div>
      <Sidebar currentPageName={'Clients'} />
      <main className="lg:ml-72">
        <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 mb-12">
          {/* If error loading client*/}
          {clientError && <ErrorBanner message={clientError.message} />}
          {/* If error loading meetings */}
          {meetingsError && <ErrorBanner message={meetingsError.message} />}

          {/* Heading */}
          <div className="border-b border-gray-200 pt-4 pb-6 text-gray-800">
            <h3 className="text-3xl font-bold">{client?.name}</h3>
          </div>

          <div className="rounded mt-8 ">
            <div className="flex align-items items-center">
              <h4 className="flex text-lg font-semibold text-gray-800">Action Items</h4>
              {/* Tooltip */}
              <div className="ml-1.5 text-left relative group mt-0.5">
                <QuestionMarkCircleIcon
                  className="h-4 w-4 text-gray-400 hover:stroke-2 hover:text-gray-300 transition-stroke duration-300"
                  aria-hidden="false"
                />
                <span className="absolute block bottom-full bg-gray-500 left-8 leading-4 top-0 h-fit w-64 text-white px-3 pt-2 pb-2.5 text-xs rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                  These action items are automatically generated from your meeting notes with{' '}
                  {client!.name}.
                </span>
              </div>
            </div>
            <ActionItems
              actionItems={
                client!.id === HARDCODED_CLIENT_WITH_ACTION_ITEMS
                  ? [
                      { action: 'Fill out budget sheet', done: true },
                      { action: 'Research support programs for single parents', done: true },
                      { action: 'Find housing options', done: false },
                      { action: 'Submit release of information forms', done: false }
                    ]
                  : []
              }
              clientName={client!.name}
            />
          </div>

          <div className="w-full rounded mt-12">
            <h4 className="text-lg font-semibold text-gray-800">Meetings</h4>
            {sortedMeetings && sortedMeetings.length > 0 ? (
              <ul role="list" className="space-y-6 mt-4 lg:w-3/4">
                {sortedMeetings.map((meeting, meetingIdx) => (
                  <ClientFeedItem
                    key={meeting.id}
                    meeting={meeting}
                    isLastInFeed={meetingIdx === sortedMeetings.length - 1}
                  />
                ))}
              </ul>
            ) : (
              // Empty state
              <div className="rounded-md bg-indigo-50 border border-indigo-100 p-4 mt-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-indigo-700">
                      You have no meetings with {client!.name} yet.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <RecordAudioButton />
      </main>
    </div>
  );
}
