import useNavigation from '../common/navigation';
import { BasicMeetingData } from '../common/types';

interface BasicMeetingDataSectionProps {
  basicMeetingData: BasicMeetingData;
  setBasicMeetingData: (basicMeetingData: BasicMeetingData) => void;
}

export default function BasicMeetingDataSection({
  basicMeetingData,
  setBasicMeetingData
}: BasicMeetingDataSectionProps) {
  // const { data: clients, error: clientsError } = useQuery({
  //   queryKey: ['getClients'],
  //   queryFn: () => getClients()
  // });

  const { goToClientPage, goToTemplatePage } = useNavigation();

  return (
    <div className="mx-auto max-w-7xl py-4 sm:px-8 lg:px-12 mt-2">
      {/* Error retrieving clients */}
      {/* {clientsError && <ErrorBanner message={clientsError.message} />} */}

      {/* Worker - this is ineditable because the worker cannot be changed */}
      <div className="px-4 py-3 sm:py-1 sm:grid sm:grid-cols-[25%_75%] sm:px-0 items-center">
        <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
          Worker
        </label>
        <p
          id="worker"
          className="col-span-2 sm:col-span-1 mt-1 sm:mt-0 py-1.5 sm:px-1.5 text-gray-600 outline-none placeholder:text-gray-400 sm:text-md sm:leading-6 w-full"
        >
          {basicMeetingData.worker}
        </p>
      </div>

      {/* Client */}
      <div className="px-4 py-3 sm:py-1 sm:grid sm:grid-cols-[25%_75%] sm:px-0 items-center">
        <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
          Client
        </label>
        <div className="w-fit">
          <button
            onClick={() => goToClientPage(basicMeetingData.client.id)}
            id="client"
            className="bg-indigo-50/70 col-span-2 sm:col-span-1 mt-1 my-1 sm:mt-0 py-0.5 my-2.5 px-1 border-b border-indigo-300 text-indigo-900/90 text-left font-medium outline-none w-fit placeholder:text-gray-400 sm:text-md sm:leading-6 w-full hover:text-indigo-500/100 hover:border-indigo-500 transition-color duration-300"
          >
            {basicMeetingData.client.name}
          </button>
        </div>
      </div>

      {/* Template */}
      <div className="px-4 py-3 sm:py-1 sm:grid sm:grid-cols-[25%_75%] sm:px-0 items-center">
        <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
          Meeting Template
        </label>
        <div id="template-link" className="w-fit">
          <button
            onClick={() => goToTemplatePage(basicMeetingData.template.id!, false)}
            id="template"
            className="bg-indigo-50/70 col-span-2 text-left sm:col-span-1 mt-1 sm:mt-0 py-0.5 my-2.5 px-1 border-b border-indigo-300 text-indigo-900/90 font-medium outline-none w-fit placeholder:text-gray-400 sm:text-md sm:leading-6 w-full hover:text-indigo-500/100 hover:border-indigo-500 transition-color duration-300"
          >
            {basicMeetingData.template.title}
          </button>
        </div>
      </div>

      {/* Date and Time */}
      <div className="px-4 py-3 sm:py-1 sm:grid sm:grid-cols-[25%_75%] sm:px-0 items-center">
        <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
          Date
        </label>
        <input
          type="date"
          name="date"
          id="date"
          className="col-span-2 sm:col-span-1 mt-1 sm:mt-0 py-1.5 sm:px-2 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-200 focus:border-indigo-600 placeholder:text-gray-400 sm:text-md sm:leading-6 w-full"
          value={basicMeetingData.date}
          onChange={(e) => setBasicMeetingData({ ...basicMeetingData, date: e.target.value })}
          required
        />
      </div>
      <div className="px-4 py-3 sm:py-1 sm:grid sm:grid-cols-[25%_75%] sm:px-0 items-center">
        <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
          Time
        </label>
        <input
          type="time"
          name="time"
          id="time"
          className="col-span-2 sm:col-span-1 mt-1 sm:mt-0 py-1.5 sm:px-2 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-200 focus:border-indigo-600 placeholder:text-gray-400 sm:text-md sm:leading-6 w-full"
          value={basicMeetingData.time}
          onChange={(e) => setBasicMeetingData({ ...basicMeetingData, time: e.target.value })}
          required
        />
      </div>

      {/* Location */}
      <div className="px-4 py-3 sm:py-1 sm:grid sm:grid-cols-[25%_75%] sm:px-0 items-center">
        <label className="text-sm flex font-medium capitalize leading-6 text-gray-900 col-span-2 sm:col-span-1">
          Location
        </label>
        <input
          type="text"
          name="location"
          id="location"
          className="col-span-2 sm:col-span-1 mt-1 sm:mt-0 py-1.5 sm:px-2 text-gray-600 focus:text-gray-900 border-b outline-none border-gray-200 focus:border-indigo-600 placeholder:text-gray-400 sm:text-md sm:leading-6 w-full"
          value={basicMeetingData.location}
          onChange={(e) => setBasicMeetingData({ ...basicMeetingData, location: e.target.value })}
        />
      </div>
    </div>
  );
}
